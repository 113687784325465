import { useState } from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { routesMenu } from "../routes";
import Container from "./Container";
import { COLORS } from "../styles/colors";

const Header = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const languages = [
    { id: "en", name: "english" },
    { id: "fr", name: "france" },
  ];
  const [activeLanguage, setActiveLanguage] = useState(languages[0]);

  const location = useLocation();

  return (
    <>
      <Navbar
        expand="lg"
        style={{ borderBottom: `1px solid ${COLORS.Border}` }}
      >
        <Container>
          <Navbar.Brand style={{ marginRight: 0 }}>
            <div className="navbar-brand p-0 me-0 me-lg-4 d-flex align-items-center">
              <Link to="https://fao.org/home">
                <img
                  src="https://www.fao.org/images/corporatelibraries/fao-logo/fao-logo-archive/fao-logo-black-3lines-en.svg"
                  alt="FAO logo"
                  width="100%"
                  height="40px"
                />
              </Link>
              <Link to="/">
                <p
                  className="ms-3 mb-0 border-start ps-3 nav-link fw-semibold d-none d-lg-block"
                  style={{
                    fontSize: "0.9rem",
                    color: COLORS.Title,
                    fontWeight: 400,
                  }}
                >
                  AGRITECH OBSERVATORY
                </p>
              </Link>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {routesMenu.map((route) => (
                <Nav.Link
                  key={route.title}
                  href={route.path}
                  active={location.pathname === route.path}
                  style={{ fontSize: "0.9rem" }}
                >
                  {route.title}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
          {/* <Navbar.Collapse
            id="basic-navbar-nav"
            style={{ flexGrow: "inherit" }}
          >
            <Dropdown
              title={activeLanguage.name}
              id="basic-nav-dropdown"
              style={{ textTransform: "uppercase" }}
            >
              {languages.map((language) => (
                <NavDropdown.Item
                  key={language.id}
                  onClick={() => {
                    changeLanguage(language.id);
                    setActiveLanguage(language);
                  }}
                >
                  {language.name}
                </NavDropdown.Item>
              ))}
            </Dropdown>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
    </>
  );
};

export default Header;

const Dropdown = styled(NavDropdown)`
  border-color: var(--bs-btn-active-border-color);
  background-color: ${COLORS.GrayLight} !important;
  cursor: pointer;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
  & > a {
    padding: 0 !important;
    margin: 0.25rem 0.5rem !important;
  }
`;
