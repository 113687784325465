import React from "react";
import { Col, Row } from "react-bootstrap";

interface IProps {
  title?: string;
  image: string;
  children: React.ReactNode;
}

const SectionTextWithImages = ({ title, image, children }: IProps) => {
  return (
    <Row style={{ marginBottom: "1rem" }}>
      {title && (
        <Col sm="12" style={{ marginBottom: "1rem" }}>
          <h2>{title}</h2>
        </Col>
      )}
      <Col sm="4">
        <img
          src={image}
          alt="fao-image-1"
          style={{ marginBottom: "1rem", width: "100%" }}
        />
      </Col>
      <Col sm="8">{children}</Col>
    </Row>
  );
};

export default SectionTextWithImages;
