import "bootstrap/dist/css/bootstrap.min.css";
import "fao-design-system/fao-theme.min.css";
import { Navigate, Route, Routes } from "react-router-dom";
import Container from "./components/Container";
import Footer from "./components/Footer";
import GlobalStyle from "./styles/GlobalStyle";
import Header from "./components/Header";
import AboutPage from "./pages/AboutPage";
import AnalyticsDetailsPage from "./pages/AnalyticsDetailsPage";
import AnalyticsPage from "./pages/AnalyticsPage";
import SubmitPage from "./pages/SubmitPage";
import TaxonomyPage from "./pages/TaxonomyPage";
import routes from "./routes";
import "./utils/i18n";

function App() {
  const lang = "en";

  return (
    <div className="App">
      <Header />
      <Container>
        <Routes>
          <Route path="/">
            <Route
              index
              element={<Navigate to={routes.analytics.path} replace />}
            />
            <Route index path={routes.about.path} element={<AboutPage />} />
            <Route
              index
              path={routes.taxonomy.path}
              element={<TaxonomyPage />}
            />
            <Route index path={routes.submit.path} element={<SubmitPage />} />
            <Route
              index
              path={routes.analytics.path}
              element={<AnalyticsPage />}
            />
            <Route
              path={routes.analyticsDetails.path}
              element={<AnalyticsDetailsPage />}
            />
          </Route>
        </Routes>
        <GlobalStyle />
        <Footer lang={"en"} />
      </Container>
    </div>
  );
}

export default App;
