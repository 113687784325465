import { MutableRefObject } from "react";

interface IProps {
  tableauContainerRef: MutableRefObject<HTMLDivElement>;
  initiatives: Project[];
}

const TableauCharts = ({ tableauContainerRef, initiatives }: IProps) => {
  return (
    <div
      ref={tableauContainerRef}
      style={{
        display: initiatives.length > 0 ? "block" : "none",
      }}
    />
  );
};

export default TableauCharts;
