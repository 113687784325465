import React from "react";
import { Col, Row } from "react-bootstrap";
import { ReferenceDataInterface } from "../constants/taxonomyConstants";

interface IProps {
  record?: ReferenceDataInterface;
}

const References = ({record}: IProps) => {
  const {title,description,citation, subDescription, url} = record
  return (
    <Row style={{ marginBottom: "1rem" }}>
      {record && (
        <Col sm="12" style={{ marginBottom: "0.5rem", fontSize: '1rem' }}>
          {title && <strong><span>{title}</span></strong>}
          {description && <span>{description}</span>}
          {citation && <span>{citation}</span>}
          {subDescription && <i>{subDescription}</i>}
          {url && <a href={url} target="_blank" rel="noreferrer">{url}</a>}
        </Col>
      )}
    </Row>
  );
};

export default References;
