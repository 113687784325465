import BetterEnvironment from "../assets/fourBetter/Better_Environment.png";
import BetterLife from "../assets/fourBetter/Better_Life.png";
import BetterNutrition from "../assets/fourBetter/Better_Nutrition.png";
import BetterProduction from "../assets/fourBetter/Better_Production.png";

const fourBetters = {
  "Better production": BetterProduction,
  "Better life": BetterLife,
  "Better nutrition": BetterNutrition,
  "Better environment": BetterEnvironment,
};

export type FourBetter = keyof typeof fourBetters;

interface IProps {
  fourBetter: FourBetter;
}

const FourBettersRenderer = ({ fourBetter }: IProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.2rem",
        marginLeft: "0.5rem",
      }}
    >
      <img
        alt=""
        src={fourBetters[fourBetter]}
        style={{ height: 28, width: 28 }}
      ></img>
      <p style={{ fontSize: "0.8rem" }}>{fourBetter}</p>
    </div>
  );
};

export default FourBettersRenderer;
