import React from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import FourBettersRenderer, { FourBetter } from "./FourBettersRenderer";
import SDGIconRenderer, { SDG } from "./SDGIconRenderer";

interface IProps {
  data: string | string[];
  type?: undefined | "badge" | "link" | "fourBetters" | "sdgs";
}

const RenderData: React.FC<IProps> = ({ data, type }) => {
  const renderByType = (data: string, type?: string) => {
    switch (type) {
      case "badge":
        return <Badge>{data}</Badge>;
      case "link":
        return (
          <a href={data} target="_blank" rel="noopener noreferrer">{data}</a>
        );
      case "fourBetters":
        return <FourBettersRenderer fourBetter={data as FourBetter} />;
      case "sdgs":
        return <SDGIconRenderer sdg={data as SDG} />;
      default:
        return <span>{data}</span>;
    }
  };

  return (
    <div>
      {typeof data === "string" && renderByType(data, type)}
      {Array.isArray(data) && !type && renderByType(data.join(", "), type)}
      {Array.isArray(data) && type && (
        <div style={{ display: "flex", gap: "0.25rem", flexWrap: "wrap" }}>
          {data.map((element) => renderByType(element, type))}
        </div>
      )}
    </div>
  );
};

export default RenderData;
