import BC from "react-bootstrap/Breadcrumb";

interface IProps {
  sections: {
    id: string;
    name: string;
    link?: string;
  }[];
}

const Breadcrumb = ({ sections }: IProps) => {
  return (
    <BC style={{ margin: "2rem 0" }}>
      {sections.map(({ id, name, link }) => (
        <BC.Item key={id} href={link} active={!link}>
          {name}
        </BC.Item>
      ))}
    </BC>
  );
};

export default Breadcrumb;
