import styled from "styled-components";
import Icon from "./Icon";
import { COLORS } from "../styles/colors";

interface IProps {
  value?: string;
  label?: string;
  placeholder?: string;
  setState?: React.Dispatch<React.SetStateAction<string>>;
  onClean?: () => void;
  onReset?: () => void;
}

const TextInput = ({
  value,
  label,
  placeholder,
  setState,
  onClean,
  onReset,
}: IProps) => {
  return (
    <div>
      {label && <p style={{ marginBottom: 0, fontSize: 12 }}>{label}</p>}

      <InputContainer>
        <Input
          value={value}
          placeholder={placeholder}
          onChange={(e) => setState(() => e.target.value)}
        />
        {onClean && (
          <InputContainerIcon onClick={onClean}>
            <Icon icon="xmark" tooltip="Delete" style={{ fontSize: 18 }} />
          </InputContainerIcon>
        )}
        {onReset && (
          <InputContainerIcon onClick={onReset}>
            <Icon
              icon="rotate"
              tooltip="Clean selection"
              style={{ fontSize: 14 }}
            />
          </InputContainerIcon>
        )}
      </InputContainer>
    </div>
  );
};

export default TextInput;

const Input = styled.input`
  color: ${COLORS.GrayDark};
  padding: 0.375rem 0 0.375rem 0.75rem;
  border: none;
  width: 100%;
  outline: 0;
  border-radius: 0.375rem;

  &::placeholder {
    font-weight: 300;
  }
`;

const InputContainer = styled.div`
  display: flex;
  border-radius: 0.375rem;
  border: 1px solid ${COLORS.Border};
  width: 100%;
`;

const InputContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  cursor: pointer;
`;
