import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../styles/colors";

const ContactsCard = () => {
  return (
    <ContactRow>
      <Col sm="4" xs="12" style={{ marginBottom: "0.5rem" }}>
        <h5 style={{ fontWeight: "500" }}>Digital Village Initiative</h5>
        <Link to="https://www.fao.org/digital-villages-initiative/europe/en">
          Digital Village Initiative in Europe and Central Asia
        </Link>
      </Col>
      <Col sm="4" xs="12" style={{ marginBottom: "0.5rem" }}>
        <h5 style={{ fontWeight: "500" }}>Digital agriculture resources</h5>
        <Link to="https://www.fao.org/europe/resources/digital-agriculture/en">
          Digital agriculture | FAO Regional Office for Europe and Central Asia
        </Link>
      </Col>
      <Col sm="4" xs="12" style={{ marginBottom: "0.5rem" }}>
        <h5 style={{ fontWeight: "500" }}>Contact</h5>
        <Link to="mailto:reu-digital-agriculture@fao.org">
          reu-digital-agriculture@fao.org
        </Link>
      </Col>
    </ContactRow>
  );
};

export default ContactsCard;

const ContactRow = styled(Row)`
  background: ${COLORS.GrayLight};
  border-radius: 5px;
  padding: 1rem 0;
  box-sizing: border-box;
  margin-bottom: 4rem;
`;
