import SDG1 from "../assets/sdg/E-WEB-Goal-01.png";
import SDG2 from "../assets/sdg/E-WEB-Goal-02.png";
import SDG3 from "../assets/sdg/E-WEB-Goal-03.png";
import SDG4 from "../assets/sdg/E-WEB-Goal-04.png";
import SDG5 from "../assets/sdg/E-WEB-Goal-05.png";
import SDG6 from "../assets/sdg/E-WEB-Goal-06.png";
import SDG7 from "../assets/sdg/E-WEB-Goal-07.png";
import SDG8 from "../assets/sdg/E-WEB-Goal-08.png";
import SDG9 from "../assets/sdg/E-WEB-Goal-09.png";
import SDG10 from "../assets/sdg/E-WEB-Goal-10.png";
import SDG11 from "../assets/sdg/E-WEB-Goal-11.png";
import SDG12 from "../assets/sdg/E-WEB-Goal-12.png";
import SDG13 from "../assets/sdg/E-WEB-Goal-13.png";
import SDG14 from "../assets/sdg/E-WEB-Goal-14.png";
import SDG15 from "../assets/sdg/E-WEB-Goal-15.png";
import SDG16 from "../assets/sdg/E-WEB-Goal-16.png";
import SDG17 from "../assets/sdg/E-WEB-Goal-17.png";
import Tooltip from "./Tooltip";

const sdgs = {
  "SDG 1: No poverty": SDG1,
  "SDG 2: Zero hunger": SDG2,
  "SDG 3: Good health and well-being": SDG3,
  "SDG 4: Quality education": SDG4,
  "SDG 5: Gender equality": SDG5,
  "SDG 6: Clean water and sanitation": SDG6,
  "SDG 7: Affordable and clean energy": SDG7,
  "SDG 8: Decent work and economic growth": SDG8,
  "SDG 9: Industry, innovation, and infrastructure": SDG9,
  "SDG 10: Reduced inequalities": SDG10,
  "SDG 11: Sustainable cities and communities": SDG11,
  "SDG 12: Responsible consumption and production": SDG12,
  "SDG 13: Climate action": SDG13,
  "SDG 14: Life below water": SDG14,
  "SDG 15: Life on land": SDG15,
  "SDG 16: Peace, justice and strong institutions": SDG16,
  "SDG 17: Partnerships for the goals": SDG17,
};

export type SDG = keyof typeof sdgs;

interface IProps {
  sdg: SDG;
}

const SDGIconRenderer = ({ sdg }: IProps) => {
  return (
    <Tooltip text={sdg}>
      <img alt="" src={sdgs[sdg]} style={{ height: 48, width: 48 }}></img>
    </Tooltip>
  );
};

export default SDGIconRenderer;
