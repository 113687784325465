import React, { useState } from "react";

interface IProps {
  text: string;
  children: React.ReactNode;
}

const Tooltip = ({ text, children }: IProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <span
      style={{
        position: "relative",
      }}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      <div
        style={{
          position: "absolute",
          left: 0,
          bottom: "100%",
          textAlign: "center",
          display: text && showTooltip ? "block" : "none",
          background: "black",
          color: "white",
          padding: "0.5rem",
          borderRadius: "4px",
          transform: "translateY(-5px)",
          whiteSpace: "nowrap",
          fontSize: "0.9rem",
        }}
      >
        {text}
      </div>
    </span>
  );
};

export default Tooltip;
