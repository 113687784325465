import React from "react";
import styled from "styled-components";
import { COLORS } from "../styles/colors";

interface IProps {
  title?: string;
  subtitle?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

const Page = ({ title, actions, subtitle, children }: IProps) => {
  return (
    <main>
      {title && (
        <PageHeader>
          <div>
            <h1>{title}</h1>
            <p>{subtitle}</p>
          </div>
          <div>{actions}</div>
        </PageHeader>
      )}
      <div>{children}</div>
    </main>
  );
};

export default Page;

const PageHeader = styled.div`
  padding: 2rem 0;
  border-bottom: 1px solid ${COLORS.Border};
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
