const routes = {
  analytics: { path: "/analytics", title: "Home" },
  analyticsDetails: { path: "/analytics/:id", title: "Details" },
  about: { path: "/about", title: "About us" },
  taxonomy: { path: "/taxonomy", title: "Taxonomy" },
  submit: { path: "/submit", title: "Submit your initiative" },
};

export default routes;

export const routesMenu = [
  routes.analytics,
  routes.taxonomy,
  routes.about,
  routes.submit
];
