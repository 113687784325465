import React from "react";
import { Container as C } from "react-bootstrap";

interface IProps {
  children: React.ReactNode;
}

const Container = ({ children }: IProps) => {
  return <C>{children}</C>;
};

export default Container;
