import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import styled from "styled-components";
import { COLORS } from "../styles/colors";

interface IProps {
  project: Partial<Project>;
}

const ProjectCard = ({ project }: IProps) => {
  return (
    <Card style={{ marginBottom: 0 }}>
      <Card.Body
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <H6 style={{ textAlign: "end" }}>{project.type}</H6>
          <Link to={`/analytics/${project.id}`}>
            <Card.Title
              style={{
                fontSize: "1.6rem",
                fontWeight: 400,
                color: COLORS.Primary,
              }}
            >
              {project.name}
            </Card.Title>
          </Link>
          <Card.Text style={{ fontSize: "1rem" }}>{project.sub_name}</Card.Text>

          {project.country_of_origin && (
            <H6
              className="title-category"
              style={{
                marginTop: "0.5rem",
              }}
            >
              ORIGIN:{" "}
              <span style={{ color: COLORS.GrayDark }}>
                {project.country_of_origin}
              </span>
            </H6>
          )}
        </div>

        <Link to={`/analytics/${project.id}`} target="_blank">
          <Card.Link
            as="span"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span>Read more</span>
            <Icon icon="chevronRight" />
          </Card.Link>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default ProjectCard;

export const ProjectCardSkeleton = () => {
  return (
    <Card style={{ marginBottom: 0 }}>
      <Card.Body
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "0.3rem",
        }}
      >
        <Card.Link className="placeholder col-6" />
        <h6 className="title-category placeholder col-7" />
        <h6 className="title-category placeholder col-6" />
        <p className="placeholder col-12" />
        <p className="placeholder col-7" />
        <Card.Link className="placeholder col-6" />
      </Card.Body>
    </Card>
  );
};

const H6 = styled.h6`
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
`;
