import { Link } from "react-router-dom";
import image1 from "../assets/aboutUs/about-us-1.png";
import image2 from "../assets/aboutUs/about-us-2.jpg";
import ContactsCard from "../components/ContactsCard";
import SectionTextWithImages from "../components/SectionTextWithImages";
import Page from "../templates/Page";

const AboutPage = () => {
  return (
    <Page title="About us">
      <SectionTextWithImages image={image1}>
        <p style={{ marginBottom: "1rem" }}>
          The AgriTech Observatory is a virtual space that navigates the users
          through the realm of digital agriculture initiatives across Europe and
          Central Asia. This space can be used by practitioners, innovators,
          policymakers, researchers, and other stakeholders to explore agritech
          solutions, good practices, policies, projects, and publications. As a
          knowledge hub, the observatory can aid in monitoring digital
          agriculture developments, performing regional and country level
          analyses, informing policy making and programmes, and guiding
          investment decisions.
        </p>
        <p style={{ marginBottom: "1rem" }}>
          Established by the FAO Regional Office for Europe and Central Asia,
          the AgriTech Observatory is the first of its kind dedicated to the
          countries in the region. Within the Observatory&#39;s virtual space,
          users can explore the ever-changing landscape of digital agriculture
          through interactive filters, a map, and analytics, delving into a
          wealth of information tailored to their needs. Each initiative is
          thoroughly described detailing its purposes, technology, thematic area
          of application, and more, allowing the users to navigate through
          hundreds of digital agriculture undertakings across the region and
          beyond.{" "}
          {/* You can read more of the Observatory’s Taxonomy{" "}
            <Link to="/taxonomy">here</Link>. */}
        </p>
        <p style={{ marginBottom: "1rem" }}>
          The establishment of the Observatory involved extensive research
          undertaken by a team of experts at the FAO Regional Office for Europe
          and Central Asia with the invaluable support of JengaLab, the Central
          European University, the American University of Central Asia, and the
          Georgian Farmers Association.
        </p>
      </SectionTextWithImages>
      <SectionTextWithImages
        title="We are growing our Observatory!"
        image={image2}
      >
        <p style={{ marginBottom: "1rem" }}>
          Do you have an agritech initiative applied in Europe and Central Asia
          that deserves recognition?
        </p>
        <p style={{ marginBottom: "1rem" }}>
          We invite you to <Link to="/submit">submit your initiative</Link> for
          a chance to be featured in our Observatory.
        </p>
        <p style={{ marginBottom: "1rem" }}>
          If you are interested in contributing to the expansion of our
          knowledge hub through additional research, kindly express your intent
          by contacting us at REU-Digital-Agriculture@fao.org.
        </p>
      </SectionTextWithImages>
      <ContactsCard />
    </Page>
  );
};

export default AboutPage;
