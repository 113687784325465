import { useMemo, useState } from "react";
import styled from "styled-components";
import Icon from "./Icon";
import TextInput from "./TextInput";
import { COLORS } from "../styles/colors";

interface IProps {
  id: string;
  label: string;
  placeholder: string;
  list: string[];
  activeList: string[];
  setState: any;
  disabled?: boolean;
  activeDropdown?: string;
  setActiveDropdown?: any;
  enableSearch?: boolean;
}

const FilterSelectDropdown = ({
  id,
  label,
  placeholder,
  list,
  activeList,
  setState,
  disabled,
  activeDropdown,
  setActiveDropdown,
  enableSearch,
}: IProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const addItemToList = (item) => {
    if (item.value === "") {
      setState({ [id]: [] });
    } else {
      setState({ [id]: [...activeList, item] });
    }
  };

  const deleteItemFromList = (item) => {
    setState({ [id]: [...activeList.filter((list) => list !== item)] });
  };

  const searchResults = useMemo(() => {
    if (searchTerm === "") {
      return list;
    } else {
      return list.filter((item) =>
        item.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }, [searchTerm, list]);

  return (
    <MultipleSelectContainer>
      <MultipleSelectInput>
        <div
          style={{
            flex: 1,
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setActiveDropdown(activeDropdown === id ? null : id)}
        >
          {label}
        </div>
        <div style={{ display: "flex" }}>
          {activeList && activeList.length > 0 && (
            <Icon
              icon="rotate"
              tooltip="Clean selection"
              onClick={
                activeList.length !== 0 ? () => setState({ [id]: [] }) : null
              }
            />
          )}
          <Icon
            icon={activeDropdown === id ? "chevronUp" : "chevronDown"}
            onClick={() => setActiveDropdown(activeDropdown === id ? null : id)}
          />
        </div>
      </MultipleSelectInput>
      {activeDropdown === id && (
        <>
          {enableSearch ? (
            <TextInput
              placeholder={placeholder}
              value={searchTerm}
              setState={setSearchTerm}
              onClean={searchTerm !== "" ? () => setSearchTerm("") : null}
            />
          ) : (
            <div style={{ borderBottom: `1px solid ${COLORS.Border}` }} />
          )}
          <MultipleSelectContentContainer $disabled={disabled}>
            <MultipleSelectContent>
              {searchResults.length === 0 ? (
                <p
                  style={{
                    minHeight: 32,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  No results
                </p>
              ) : (
                searchResults.map((item) => {
                  const selected = activeList.includes(item);
                  return (
                    <MultipleSelectOption
                      key={item}
                      selected={selected}
                      onClick={() => {
                        if (!disabled) {
                          !selected
                            ? addItemToList(item)
                            : deleteItemFromList(item);
                        }
                      }}
                    >
                      <Checkbox>{selected && <Icon icon="check" />}</Checkbox>
                      <div>
                        <p>{item}</p>
                      </div>
                    </MultipleSelectOption>
                  );
                })
              )}
            </MultipleSelectContent>
          </MultipleSelectContentContainer>
        </>
      )}
    </MultipleSelectContainer>
  );
};

export default FilterSelectDropdown;

const MultipleSelectContent = styled.div`
  color: #212529;
  top: 100%;
  width: 100%;
  cursor: pointer;
  overflow-y: scroll;
  z-index: 10;
  max-height: 250px;

  &::-webkit-scrollbar-thumb {
    background: #8d8d8d66;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
`;

const MultipleSelectContentContainer = styled.div<{ $disabled: boolean }>`
  padding: 0.5rem 0 0.75rem 0;
  background: ${(props) => (!props.$disabled ? "inherit" : COLORS.WhiteFAO)};
`;

const MultipleSelectInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 48px;
`;

interface MultipleSelectOptionProps {
  selected: boolean;
}

const MultipleSelectOption = styled.div<MultipleSelectOptionProps>`
  min-height: 32px;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  div:first-child {
    margin-top: 0.15rem;
  }

  &:hover {
    background-color: #00000009;
  }
`;

const Checkbox = styled.div`
  height: 18px;
  width: 18px;
  border: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MultipleSelectContainer = styled.div`
  position: relative;
  border-bottom: 1px solid ${COLORS.Border};
  width: 300px;
  font-size: 1rem;

  @media (max-width: 767px) {
    width: 100%;
  }
`;
