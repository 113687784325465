import React from "react";
import { Accordion } from 'react-bootstrap';

interface IProps {
    heading: string,
    index: string,
    children?: React.ReactNode
}
const AccordionItemData = ({ heading, index, children }: IProps) => {
    return (
        <Accordion.Item eventKey={index}>
            <Accordion.Header><h6>{heading}</h6></Accordion.Header>
            <Accordion.Body>
                {children}
            </Accordion.Body>
        </Accordion.Item>
    )
}

export default AccordionItemData;