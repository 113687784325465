import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AppStore from "../assets/images-fao-footer/app-store.e4e27c5cc509cfa2fe3d25cf8fcfb7e3.svg";
import FaoLogo from "../assets/images-fao-footer/fao-logo-sdg.d6b4d573a50ad1c00141df2eed97603e.svg";
import GooglePlay from "../assets/images-fao-footer/google-play.6a675242635e942d3c8e5b5ee57a1d98.svg";
import Facebook from "../assets/images-fao-footer/social-icon-facebook.63c5fa61692db577045c971910963d3f.svg";
import Flickr from "../assets/images-fao-footer/social-icon-flickr.d67aa97cb9a60db08ed2130c81bd997f.svg";
import Instagram from "../assets/images-fao-footer/social-icon-instagram.d3eecf33c9514159f36a0928b6a3ca3f.svg";
import Linkedin from "../assets/images-fao-footer/social-icon-linkedin.c62239aacd6e481042f92156a284bd84.svg";
import Rss from "../assets/images-fao-footer/social-icon-rss.281bf24d7c4410186c85256ba80286cb.svg";
import Slideshare from "../assets/images-fao-footer/social-icon-slideshare.0f191e9fd23461a41be8e81d452f0db3.svg";
import SoundCloud from "../assets/images-fao-footer/social-icon-soundcloud.svg";
import TikTok from "../assets/images-fao-footer/social-icon-tiktok.6638bdf07edd5e5cfe0d1d625415764d.svg";
import TuoTiao from "../assets/images-fao-footer/social-icon-tuotiao.e23f0d895290d90fa61a569e63b36a0c.svg";
import X from "../assets/images-fao-footer/social-icon-twitter.svg";
import WeChat from "../assets/images-fao-footer/social-icon-wechat.svg";
import Weibo from "../assets/images-fao-footer/social-icon-weibo.8d086fbadf4dfd9e1cacac28fcc13d01.svg";
import Youtube from "../assets/images-fao-footer/social-icon-youtube.fe3d66a05f96f7ff73596ebca3d9d59c.svg";
import { COLORS } from "../styles/colors";

const Footer = ({ lang }) => {
  return (
    <FooterContainer id="footer">
      <Row style={{ marginBottom: "0.875rem" }}>
        <Col sm={7} style={{ marginBottom: "1rem" }}>
          <img alt="FAO Logo" src={FaoLogo}></img>
        </Col>
        <Col sm={5} style={{ marginBottom: "1rem" }}>
          <h6
            style={{
              fontWeight: 700,
              textTransform: "uppercase",
              color: COLORS.FooterTitle,
              fontSize: 12,
              marginBottom: "0.5rem",
            }}
          >
            FOLLOW US ON
          </h6>
          <div
            className="social"
            style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}
          >
            {socialLinksFooter.map((logos) => (
              <a
                key={logos.link}
                href={logos.link}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="FAO Logo"
                  src={logos.src}
                  style={{ height: "1.5rem", width: "1.5rem" }}
                ></img>
              </a>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={7}>
          <FooterLinksTop>
            <Link to="http://www.fao.org/about/org-chart/en/">
              FAO Organizational Chart
            </Link>
            <DropdownButton
              id="Worldwide Offices"
              drop={"up"}
              variant="secondary"
              title="Worldwide offices"
            >
              {dropdownLinks.map((item) => (
                <Dropdown.Item
                  key={item.name}
                  eventKey={item.name}
                  href={item.href}
                >
                  {item.name}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </FooterLinksTop>
          <FooterLinksBottom>
            <ul className="list-inline">
              {relatedLinks.map((item) => (
                <li key={item.name}>
                  <Link to={item.link}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </FooterLinksBottom>
        </Col>
        <Col sm={5}>
          <Link
            to="/contact-us/terms/en/"
            className="copyright"
            style={{ color: "#545454", fontSize: 13, textDecoration: "none" }}
          >
            © FAO&nbsp;2024
          </Link>
        </Col>
      </Row>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  margin: 2.2rem 0;

  .copyright:hover {
    text-decoration: underline !important;
  }
`;

const FooterLinksTop = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 0.5rem;

  button {
    font-size: 14px;
    box-shadow: none !important;
    background: none !important;
  }

  .dropdown-item {
    &:hover {
      text-decoration: underline;
    }
    &:active {
      background-color: unset;
    }
  }
`;

const FooterLinksBottom = styled.div`
  font-size: 14px;
  border-top: 2px solid ${COLORS.GrayLight};
  padding-top: 0.5rem;

  ul {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.225rem;

    li {
      position: relative;
      margin-right: 0.5rem;
      display: inline-block;

      &:not(:last-child)::after {
        content: "";
        position: absolute;
        height: 100%;
        top: 0;
        right: -6px;
        width: 2px;
        background: #f2f2f2;
      }
    }
  }
`;

const socialLinksFooter = [
  {
    link: "https://www.facebook.com/pages/Food-and-Agriculture-Organization-of-the-United-Nations-FAO/46370758585?ref=mf",
    name: "",
    src: Facebook,
  },
  {
    link: "https://www.flickr.com/photos/faonews/collections/",
    name: "",
    src: Flickr,
  },
  {
    link: "https://instagram.com/fao/",
    name: "",
    src: Instagram,
  },
  {
    link: "https://www.linkedin.com/company/fao",
    name: "",
    src: Linkedin,
  },
  {
    link: "https://www.fao.org/news/rss-feed/en/",
    name: "",
    src: Rss,
  },
  {
    link: "https://soundcloud.com/unfao",
    name: "",
    src: SoundCloud,
  },
  {
    link: "https://www.tiktok.com/@fao",
    name: "",
    src: TikTok,
  },
  {
    link: "https://www.toutiao.com/c/user/token/MS4wLjABAAAAddfBLLYL2GZzXAetR_paFVV1-M4fdAWpUxiROxuOpl7pDGuXLCGfYg_VOogU135J/?wid=1621947153152",
    name: "",
    src: TuoTiao,
  },
  {
    link: "https://www.fao.org/home/en/#",
    name: "",
    src: WeChat,
  },
  {
    link: "https://www.weibo.com/unfao",
    name: "",
    src: Weibo,
  },
  {
    link: "https://twitter.com/FAO",
    name: "",
    src: X,
  },
  {
    link: "https://www.youtube.com/user/FAOoftheUN",
    name: "",
    src: Youtube,
  },
];

const dropdownLinks = [
  {
    name: "Regional Office for Africa",
    href: "https://www.fao.org/africa/en/",
  },
  {
    name: "Regional Office for Asia and the Pacific",
    href: "http://www.fao.org/asiapacific/en/",
  },
  {
    name: "Regional Office for Europe and Central Asia",
    href: "http://www.fao.org/europe/en/",
  },
  {
    name: "Regional Office for Latin America and the Caribbean",
    href: "http://www.fao.org/americas/en/",
  },
  {
    name: "Regional Office for the Near East and North Africa",
    href: "http://www.fao.org/neareast/en/",
  },
  {
    name: "Country Offices",
    href: "http://www.fao.org/about/who-we-are/worldwide-offices/en/#c207632",
  },
];

const relatedLinks = [
  { name: "Jobs", link: "http://www.fao.org/employment/home/en/" },
  { name: "Contact us", link: "http://fao.org/contact-us/en/" },
  {
    name: "Terms and Conditions",
    link: "http://fao.org/contact-us/terms/en/",
  },
  {
    name: "Data protection and privacy",
    link: "http://fao.org/contact-us/data-protection-and-privacy/en/",
  },
  { name: "Scam Alert", link: "http://fao.org/contact-us/scam-alert/en/" },
  {
    name: "Report Misconduct",
    link: "https://www.fao.org/contact-us/report-misconduct/en/",
  },
];
