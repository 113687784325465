const resources = {
  en: {
    translation: {
      home: {
        title: "AgriTech Observatory",
        search: "Search",
        selectCountryLabel: "Country and territory",
        selectCountryPlaceholder: "Search one or more countries",
        selectUseCaseLabel: "Use case",
        selectUseCasePlaceholder: "Search one or more use cases",
        selectThemeLabel: "Theme",
        selectThemePlaceholder: "Search one or more themes",
        selectTechnologyLabel: "Technology",
        selectTechnologyPlaceholder: "Search one or more technologies",
        buttonReset: "Reset filters",
        filters: {
          business_model: {
            label: "Business model",
            placeholder: "Search business model",
          },
          country: {
            label: "Country or territory",
            placeholder: "Search country or territory",
          },
          four_betters: {
            label: "Four betters",
            placeholder: "Search four betters",
          },
          fvc: {
            label: "Food value chain stage",
            placeholder: "Search food value chain stage",
          },
          maturity: {
            label: "Implementation stage",
            placeholder: "Search implementation stage",
          },
          organization_type: {
            label: "Organization type",
            placeholder: "Search organization type",
          },
          sdg: {
            label: "Sustainable Development Goals",
            placeholder: "Search Sustainable Development Goals",
          },
          technology: {
            label: "Technology",
            placeholder: "Search technology",
          },
          theme: {
            label: "Theme",
            placeholder: "Search theme",
          },
          type: {
            label: "Type",
            placeholder: "Search type",
          },
          use_case: {
            label: "Use case",
            placeholder: "Search use case",
          },
          users: {
            label: "Target",
            placeholder: "Search target",
          },
          origin_country: {
            label: "Origin (country or territory)",
            placeholder: "Search origin (country or territory)",
          },
        },
      },
    },
  },
  fr: {
    translation: {
      home: {
        title: "Observatoire AgriTech",
        search: "Rechercher",
        selectCountryLabel: "Pays",
        selectCountryPlaceholder: "Sélectionnez un ou plusieurs pays",
        selectUseCaseLabel: "Cas d'utilisation",
        selectUseCasePlaceholder:
          "Sélectionnez un ou plusieurs cas d'utilisation",
        selectThemeLabel: "Thème",
        selectThemePlaceholder: "Sélectionnez un ou plusieurs thèmes",
        selectTechnologyLabel: "Technologie",
        selectTechnologyPlaceholder:
          "Sélectionnez une ou plusieurs technologies",
        buttonReset: "Réinitialiser les filtres",
      },
    },
  },
  ru: {
    translation: {
      home: {
        title: "АгроТехнологическое Обозрение",
        search: "Поиск",
        selectCountryLabel: "Страна",
        selectCountryPlaceholder: "Выберите одну или несколько стран",
        selectUseCaseLabel: "Вариант использования",
        selectUseCasePlaceholder:
          "Выберите один или несколько вариантов использования",
        selectThemeLabel: "Тема",
        selectThemePlaceholder: "Выберите одну или несколько тем",
        selectTechnologyLabel: "Технология",
        selectTechnologyPlaceholder: "Выберите одну или несколько технологий",
        buttonReset: "Сбросить фильтры",
      },
    },
  },
};

export default resources;
