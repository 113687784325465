export interface TaxonomyBreakdownInterface {
    heading: string,
    subHeading?: string,
    data?: TaxonomyContentInterface[]
}

interface TaxonomyContentInterface {
    heading: string,
    content: string
}

export interface ReferenceDataInterface {
    title: string,
    description: string,
    citation: string,
    subDescription: string,
    url: string
}


export const TaxonomyTypeData: TaxonomyBreakdownInterface[] = [
    {
        heading: 'TYPE',
        subHeading: 'In this context, "type" refers to the type of digital agriculture initiative described.',
        data: [
            {
                heading: 'Solution',
                content: 'A product or service enabled by information and communication technologies (ICT) and developed in support of agricultural activities (Sylvester, 2016). '
            },
            {
                heading:'Good practice',
                content:'A successful experience involving the application of ICT in agriculture that has been tested and replicated in different contexts and can therefore be recommended as a model (FAO, 2013, 2024b, IRU 422978209104). '
            },
            {
                heading:'Policy',
                content:'The course of action and resources involving the use of ICT in agriculture and food sector adopted by a government, a person or another entity (FAO, 2024c, Entry 56009).'
            },
            {
                heading:'Project',
                content:'Planned set of interrelated tasks related to the use of ICT in agriculture and food sector to be executed over a fixed period and within certain cost and other limitations, often by development actors (FAO, 2024b, Entry 330903).'
            },
            {
                heading:'Resource',
                content:'A source of information or tool related to the use of ICT in agriculture and food sector that can be helpful for an individual, organization or a country in research, development planning, and other relevant tasks, such as publications and methodologies (Author’s own elaboration).'
            },
            {
                heading:'Digital/smart village',
                content:'Community in rural areas that use innovative solutions, to improve their resilience, building on local str and opportunities. They rely on a participatory approach to develop and implement their strategy to improve their economic, social and/or environmental conditions, in particular by mobilising solutions offered by digital technologies (Smart Villages Pilot Project, 2019). '
            }
        ]
    },
    {
        heading: 'COUNTRY OR TERRITORY',
        subHeading: 'All countries and territories reflect the “Names of Countries and Territories” (NOCS) standardization system developed by the FAO to ensure consistency in the naming of countries and territories across various languages and contexts (FAO, 2024d).',
    },
    {
        heading: 'USE CASE',
        subHeading: 'In this context, "use case" refers to use of ICT in agriculture and food sector to achieve a particular goal.',
        data:[
            {
                heading:'Production',
                content:'The initiative applies ICT for collecting real-time farm data (e.g. on weather, soil and air quality, crop maturity, animal behaviour, etc.), to remotely control agricultural operations typically through electronic devices or apparatuses, reducing the need for human labour and allowing to farm more precisely (European Union, 2024, ID 3568782 and ID 1327705).'
            },
            {
                heading:'Farm management',
                content:'The initiative applies ICT to optimize farm management activities, such as record keeping, accounting, administration, and forecasting, as well as to facilitate decision-making related to the use of resources and overall planning (FAO, 2024c, Entry 31285; Jolly and Clonts, 2020; Kay, 1986). '
            },
            {
                heading:'Access to knowledge and advisory',
                content:'The initiative applies ICT to facilitate the provision of agricultural advice and information to food producers or other actors in the food value chain and to improve their access to agricultural knowledge (FAO, 2024c, Entry 75298; Sylvester, 2016). '
            },
            {
                heading:'Access to markets',
                content:'The initiative applies ICT to provide food producers and other actors in the food value chain with access to information on the pricing of agrifood products (inputs and outputs) and to physical or virtual marketplaces where they can find, sell or purchase agrifood products and connect with suppliers, buyers or logistics providers (Miller, Saroja and Linder, 2013; Sylvester, 2016; United Nations Department for General Assembly and Conference Management, 2024). '
            },
            {
                heading:'Access to finance',
                content:'The initiative applies ICT to increase access to financial services such as banking, credit, insurance, and subsidies, to make payments, to create farmer economic identity, and more (Raithatha, 2020; Sylvester, 2016; Treinen and van der Elstraeten, 2018).'
            },
            {
                heading:'Supply chain management',
                content:'The initiative applies ICT to manage the flow of goods and services from the point of origin to the point of consumption or reduce the distance between producers and consumers, resulting in a more direct, transparent, and sustainable exchange of goods (FAO, 2024b, URI 7f69547e). '
            },
            {
                heading:'Governance and intelligence',
                content:'The initiative applies ICT to integrate a variety of data sources to monitor and provide insights and decision tools for social, economic, and political agrifood actors or to assist with implementing regulatory policies and frameworks, and collectively manage commons and relations (Sylvester, 2016; Tsan et al., 2019).'
            }
        ]
    },
    {
        heading:'THEME',
        subHeading:'In this context, “theme” refers to the thematic area of application of the initiative, building on FAO’s themes (FAO, 2024e). Each theme focuses on a particular area, activity or aspect of food and agriculture.',
        data:[
            {
                heading:'Agricultural inputs',
                content:'The initiative relates to provision or management of materials used in primary production, such as seeds, fertilizers, animal feed, veterinary drugs, equipment, fuel and machineries used in agricultural production (FAO, 2024c, Entry 2701; Law Insider, 2024).'
            },
            {
                heading:'Agritourism',
                content:'The initiative is applicable to the style of vacation in which hospitality is offered on farms or in rural areas, whereby tourists experience farming at close hand and farms attempt to strengthen their financial position (FAO, 2024b, URI 8692; FAO, 2024c, Entry 99720).'
            },
            {
                heading:'Animal production and health',
                content:'The initiative relates to animal health and production at any stage, including the breeding, raising, feeding, controlling the presence and risk of diseases, slaughtering, handling, and selling of animals and animal products (FAO, 2024c, Entries 84054 and 25034).'
            },
            {
                heading:'Beekeeping',
                content:'The initiative targets the beekeeping practice, which refers to all activities concerned with the practical management of social bee species (FAO et al., 2021).'
            },
            {
                heading:'Disaster risk management',
                content:'The initiative relates to the process of using administrative decisions, organizational, operational skills and capacities to lessen the impacts of natural hazards and related environmental and technological disasters (FAO, 2024c, Entry 84187).'
            },
            {
                heading:'Fisheries and aquaculture',
                content:'The initiative targets fishery practices, which may include capturing wild fish or raising fish through aquaculture(FAO, 2024c, Entry 98327; FAO, 2024e).'
            },
            {
                heading:'Food loss and waste',
                content:'The initiative tackles the decrease in the quantity or quality of food originally intended for human consumption by the different actors in the supply chain and at any stage, from harvest to consumption (FAO, 2014; FAO, 2024c, Entry 161275).'
            },
            {
                heading:'Food safety and quality',
                content:'The initiative focuses on assuring that food will not cause adverse health effects to the consumer when it is prepared and/or eaten and/or that it is acceptable or desirable by the consumer (FAO, 2024c, Entries 21724 and 170764; FAO and WHO, 2011).'
            },
            {
                heading:'Food services',
                content:'The initiative relates to the preparation or distribution of food, such as in restaurants, cafeterias, catering operations, and other out-of-home eating venues (FAO, 2024c, Entry 177110).'
            },
            {
                heading:'Forest management',
                content:'The initiative relates to the process of planning and implementing practices for the stewardship and use of forests to meet specific environmental, economic, social and cultural objectives (FAO, 2024b, URI 16129).'
            },
            {
                heading:'Land management',
                content:'The initiative relates to the use and development of land resources, including processes related to ownership and related rights, land value, land use and land development (FAO, 2024b, Entry, 56164; FAO, 2024c, URI 24866).'
            },
            {
                heading:'Plant production and protection',
                content:'The initiative relates to production of agricultural products, including the harvesting of wild plant products and the physical health of plants for food production, including the presence, risk, and control of diseases (European Union, 2024, ID BTB-WLW75-31; FAO, 2024c, Entry 164687).'
            },
            {
                heading:'Rural development',
                content:'The initiative focuses on economic, social, cultural and political processes aimed at the improvement of the well-being of people living in rural areas, that are often relatively isolated and sparsely populated (FAO, 2024b, URI 331049).'
            },
            {
                heading:'Social protection',
                content:'The initiative aims to reduce the economic and social vulnerability of poor, vulnerable and marginalized groups (FAO, 2024c, Entry 100197).'
            },
            {
                heading:'Soil management',
                content:'The initiative targets soil management practices aimed at ensuring that appropriate mineral elements enter the food chain or reducing the risk that mineral elements become deficient or toxic to plants (FAO, 2024g).'
            },
            {
                heading:'Statistics',
                content:'The initiative focuses on collecting categorized data from different sources that can later be used to produce agrifood statistics (FAO, 2024c, Entry 42143).'
            },
            {
                heading:'Trade',
                content:'The initiative is applied in the scope of buying and selling agrifood inputs and outputs services locally, nationally and internationally between various stakeholders (FAO, 2024b, URI 8682).'
            },
            {
                heading:'Water management',
                content:'The initiative targets development, distribution, control and use of water resources (FAO, 2010; FAO, 2024c, Entry 10163).'
            },
            {
                heading:'Weather and climate change',
                content:'The initiative focuses on monitoring weather conditions or supporting climate change mitigation (e.g., the reduction of GHG emissions, soil carbon sequestration, avoided deforestation, etc.) and adaptation (helping communities and ecosystems moderate, cope with, or take advantage of actual or expected changes in climate conditions) (FAO, 2020a).'
            }

        ]
    },
    {
        heading:'TECHNOLOGY',
        subHeading:'In this context, "technology" refers to the digital technology or information technology channel deployed. ',
        data:[
            {
                heading:'Artificial intelligence (AI)',
                content:'Artificial intelligence, or AI, is software systems that make decisions which normally require a human level of expertise, often using real-time data (FAO, 2024c, Entry 30569).'
            },
            {
                heading:'Augmented/virtual reality',
                content:"Augmented reality is a technology that superimposes a computer-generated image on a user's view of the real world, thus providing a composite view. Virtual reality is an artificial world that consists of images and sounds created by a computer and that is affected by the actions of a person experiencing it (European Union, 2024, ID 1875498 and 117704)."
            },
            {
                heading:'Big data',
                content:'An umbrella term referring to the large amounts of digital data continually generated by the global population as a byproduct of everyday interactions with digital products or services (FAO, 2024c, Entry 171397).'
            },
            {
                heading:'Blockchain',
                content:"Information technology that acts as a shared ledger for digital storage and tracking of data associated with a product or service, from the raw production stage until it lands in the consumer's hand in real time (FAO, 2024b, URI ab84e157)."
            },
            {
                heading:'Bluetooth',
                content:'A short-range wideband radio standard that allows mobile phones, palmtops and portable PCs to communicate with each other without cables (European Union, 2024, ID 1267554).'
            },
            {
                heading:'Broadcasting and radio communication',
                content:'The transmission of programmes, information or signals by radio or television (FAO, 2024b, URI 24906; FAO, 2024c, Entry 57977).'
            },
            {
                heading:'Cloud',
                content:'Infrastructure and network of servers connected via the internet and providing storage capacity and computing power to users who are also connected to the internet (European Union, 2024, ID 3527224).'
            },
            {
                heading:'Desktop/web-based application',
                content:'Software programme that is installed and runs locally on a computer’s operating system (if “desktop application”) or that is accessed over a network connection using HTTP (if “web-based application”) and that enables the end-user to perform an activity or execute specific tasks (Oxford English Dictionary, undated; PCMag, 2024).'
            },
            {
                heading:'Drone',
                content:'An unmanned aerial vehicle, commonly known as a drone, is a pilotless aircraft, in the sense of Article 8 of the Convention on International Civil Aviation, which is flown without a pilot-in-command on board and is either remotely and fully controlled from another place (ground, another aircraft, space) or programmed and fully autonomous (United Nations Department for General Assembly and Conference Management, 2024).'
            },
            {
                heading:'E-commerce/online marketplace',
                content:'Method of ordering and/or delivering products by electronic means, for example, through the internet or other computer-mediated networks (United Nations Department for General Assembly and Conference Management, 2024).'
            },
            {
                heading:'E-payment',
                content:'Payment method using information technology to link bank accounts and facilitate cash transfer from buyer to seller and occasionally vice-versa (European Union, 2024, ID 3528463).'
            },
            {
                heading:'GIS',
                content:"A computer software for capturing, storing, checking, integrating, manipulating, analysing and displaying data related to positions on the Earth's surface. Typically, GIS, or Geographical Information System, is used for handling maps of different kinds (FAO, 2024c, Entry 46395)."
            },
            {
                heading:'GPS',
                content:'Often referred to as GPS, global navigation satellite systems (GNSS) are satellite-based worldwide position-, velocity-, and time-determination systems (European Union, 2024, ID A222613).'
            },
            {
                heading:'Instant messaging',
                content:'Software that allows users to send and receive text messages, photos, videos, voice recordings, and other types of files via the internet and in real time (The Engine Room Library and International Committee of the Red Cross, undated).'
            },
            {
                heading:'Internet of things (IoT)',
                content:'Internet of things, or IoT, is a combination of sensors and various tiny devices embedded in physical objects and linked through wired and wireless networks that generate vast volumes (often big data) that can be analysed in dedicated applications (FAO, 2024c, Entry 169213).'
            },
            {
                heading:'Machine learning',
                content:'The scientific field that gives machines the ability to learn – from "experience" (training data) – without being strictly programmed to perform a task (FAO, 2024c, Entry 173133).'
            },
            {
                heading:'Mobile app',
                content:'A software application designed to run on smartphones, tablets, computers and other mobile devices (European Union, 2024, ID 3547446).'
            },
            {
                heading:'Online community',
                content:'Online-based collaborative networks such as forums, communities and portals, where people share common interests, ideas and feelings (European Union, 2024, ID 1875294).'
            },
            {
                heading:'Remote sensing',
                content:'The technology of acquiring data and information about an object from a distance, e.g., from aircraft or satellite (FAO, 2024c, 97673).'
            },
            {
                heading:'Robotics',
                content:'The branch of technology that deals with the design, construction, operation, and application of robots, which are machines capable of autonomous operation without direct human intervention (FAO, 2024c, 175437).'
            },
            {
                heading:'Sensor',
                content:'Measuring instruments or devices which detect or measure a physical property and record, indicate, or otherwise respond to it (European Union, 2024, ID 1373415; FAO, 2024b, URI 28279).'
            },
            {
                heading:'SMS',
                content:'A short message service (SMS) that consists of text of up to 160 characters sent to a phone (European Union, 2024, ID 897250).'
            },
            {
                heading:'Social media',
                content:'Refers to internet-based applications that allow people to create networks and share or exchange information. In agriculture, social media are relevant for communication, awareness raising and capacity building (e.g., e-learning tools) (FAO, 2024b, URI 8bc05c3).'
            },
            {
                heading:'Website',
                content:'A public information resource, component part of the World Wed Web, created and administered by an individual or organization. These are static pages used to display and retrieve multimedia content, but with no or limited user engagement (FAO, 2024c, Entry 40430).'
            }
        ]
    },
    {
        heading:'BUSINESS MODEL',
        subHeading:'In this context, “business model” pertains to the underlying approach through which the initiative creates and deliver value',
        data:[
            {
                heading:'Advertising',
                content:'Revenues are generated through the sale of product display advertisements allowing business to supplement or replace other income and helping reduce fees for buyers and sellers (Joiner and Okeleke, 2019).'
            },
            {
                heading:'Data monetisation',
                content:'A business model in which the solution provider sells insights from user data to third parties (Joiner and Okeleke, 2019).'
            },
            {
                heading:'Donor support',
                content:'The initiative is funded or supported by international development and donor organizations (Author’s own elaboration).'
            },
            {
                heading:'Fee for service',
                content:'A business arrangement in which the user does not own an ICT solution but pays per-use fee to use it or for services to be delivered to him on a per unit basis (e.g., per ha, per hour, per animal, per ton) with the use of this solution (e.g., drone) (Loukos and Arathoon, 2022).'
            },
            {
                heading:'Freemium',
                content:'Business model whereby solution providers offer free or low-cost basic services while more advanced features must be paid (Loukos and Arathoon, 2022).'
            },
            {
                heading:'Fully free',
                content:'Users do not have to pay for the solution. The cost is paid by someone else – the government, a development organization, or another actor in the value chain (Wongtschowski et al., 2013)'
            },
            {
                heading:'Markup',
                content:'The solution provider applies a margin to product or service price to be paid by one or both sides of the transaction (e.g., provider and end consumers). This business model is commonly used by e-commerce (Joiner and Okeleke, 2019).'
            },
            {
                heading:'One time purchase',
                content:'The customer makes a single upfront payment for a technological product or service. The purchaser maintains ownership of the technological asset (Loukos and Arathoon, 2022).'
            },
            {
                heading:'Open source',
                content:'The initiative is based on software or hardware that is openly available and can be freely used, changed, and shared (in modified or unmodified form) by anyone (European Union, 2024. ID 916081).'
            },
            {
                heading:'Subscription',
                content:'In contrast to free registration, subscription means that users pay monthly/annual/other fee to access product or service. Users can easily try out a potential solution and just terminate the subscription, should the benefits not be satisfactory (Sibona et al., 2020).'
            }
        ]

    },
    {
        heading:'IMPLEMENTATION STAGE',
        subHeading:'In this context, “implementation stage” refers to the stage of maturity of the initiative and reflects its readiness, effectiveness, and impact.',
        data:[
            {
                heading:'Early stage',
                content:'The initiative is in the early stages of development: its value propositions and ideas for capturing value via new products, services, business models and processes are developed (O’Reilly and Binns, 2019). '
            },
            {
                heading:'Market adoption',
                content:'The initiative is being validated in the market at the end of the development process (European Union, 2024, ID BTL-DPT81-510; O’Reilly and Binns, 2019). '
            },
            {
                heading:'Scale-up',
                content:'The initiative is proven and is being scaled up to expand and reach a greater number of users (FAO, 2024b, URI 6d768d91). '
            }
        ]
    },
    {
        heading:'FOOD VALUE CHAIN STAGE ',
        subHeading:'In this context, "food value chain stage" refers to the specific stage within the entire food production and distribution process where a particular initiative intervenes.',
        data:[
            {
                heading:'Production',
                content:'The production of raw agricultural, livestock, fisheries and forestry products (FAO, 2024h). '
            },
            {
                heading:'Post-harvest handling and aggregation',
                content:'Handling a crop during the initial stages following removal from the soil or parent plant to minimise quality deterioration. It includes such activities as bulking into larger volumes, cooling, cleaning, sorting, grading, packing, transport and storage (FAO, 2024h). '
            },
            {
                heading:'Processing',
                content:'The transformation of raw agricultural, livestock, fisheries or forestry products into food that consumers can readily prepare (FAO, 2024h).'
            },
            {
                heading:'Distribution',
                content:'The resale and supply of food products from a point of aggregation (e.g., packing house or factory gate) to other firms or end-consumers, including activities such as repackaging bulk volumes into smaller mixed lots, (cool) storage and transport, and marketing the products (FAO, 2024h).'
            },
            {
                heading:'Consumption',
                content:'The stage where individuals or households purchase, prepare and eat food, either at home or in various commercial settings such as restaurants, cafes, or street vendors (FAO, 2024h). '
            }
        ]
    },
    {
        heading:'TARGET',
        subHeading:'In this context, "target" refers to the individuals, groups, or entities that are users, are targeted or engage with the initiative.',
        data:[
            {
                heading:'Academia and research institutions',
                content:'Governmental or private establishments that are concerned with the pursuit of research, education and scholarship (European Union, 2024, ID 3599438; Law Insider, 2024).'
            },
            {
                heading:'Agriculture cooperatives',
                content:'Corporations or associations established to provide economic or other services to its members without gain to itself (FAO, 2024c, Entry 8808).'
            },
            {
                heading:'Civil society organizations',
                content:'Non-state actors that are inherently non-governmental member-based organizations and social movements, which have governance structure (FAO, 2024c, Entry 51301).'
            },
            {
                heading:'Farm advisors',
                content:'A farm advisor is a professional, including extension workers and consultants from public or private organization, who provides expert guidance and support to farmers and agrifood businesses on various aspects of farming and assists them in developing their own technical, organizational and management skills and practices, so as to improve their livelihoods and well-being (FAO, 2017b, 2019).'
            },
            {
                heading:'Food consumers',
                content:'One that consumes food products, especially one that acquires foods or food services for direct use or ownership rather than for resale or use in production and manufacturing (FAO, 2024b, URI 28315).'
            },
            {
                heading:'Large size farms',
                content:'Normally, commercial enterprises that tend to use modern techniques, technologies and inputs, rely on research and mechanized labour and actively market their output (McConnell and Dillon, 1997).'
            },
            {
                heading:'Medium size farms',
                content:'Farms and agricultural holdings with a high degree of activity specialization. These farms are more likely to use modern techniques, technologies, and inputs and to market their output (McConnell and Dillon, 1997).'
            },
            {
                heading:'Private sector',
                content:'The private sector encompasses for-profit enterprises, companies or businesses (including food supply chain actors from production up to the retail and food service stage) that are not owned or operated by governments, regardless of their size and structure; as well as the associated services, such as inputs, financing, investments, insurance, marketing and trade (FAO, 2024c, Entry 60757).'
            },
            {
                heading:'Public sector',
                content:'The term covers all forms of public organizations: IGOs, at global and regional levels, including UN agencies; international financial institutions (IFIs) and multilateral development banks (MDBs); states, government agencies in different sectors (including health, nutrition, agriculture, environment, finance, economics, trade, justice); local authorities (at the subnational level); public universities; public research and development (R&D) institutions; as well as other organizations, banks, companies or institutions with a public legal status (FAO, 2024c, Entry 60758).'
            },
            {
                heading:'Rural communities',
                content:'Group of individuals residing within or in the same vicinity of a particular parcel, property or natural resource, not included within an urbanised area or urban cluster (European Union, 2024, ID 818859; FAO, 2024b, URI 90000107).'
            },
            {
                heading:'Smallholder farmers',
                content:'Smallholder farmers are often characterized by limited access to production factors, natural resources and finance; using outdated technology, low input quality and skills; low productivity and competitiveness resulting from inefficient farm structures, etc. (Note that FAO does not have an official definition of smallholders. Thus, the category instead describes smallholders distinguishing features and characteristics (FAO, 2020b; FAO, 2024c, Entry 2989)).'
            }
        ]
    },
    {
        heading:'ORGANISATION TYPE ',
        subHeading:'In this context, "organization type" refers to type of entity(-ies) that are involved in implementing the initiative.',
        data:[
            {
                heading:'Central government entity',
                content:'The main government in a country, as opposed to local or provincial governments (FAO, 2024b, URI 1437).'
            },
            {
                heading:'Civil society organization',
                content:'Non-state actors that are inherently non-governmental member-based organizations and social movements, which have governance structure (FAO, 2024c, Entry 51301).'
            },
            {
                heading:'Consortium of partners',
                content:'Group of independent companies working together for the fulfilment of a specific project (European Union, 2024, ID BTC-CMP02-37).'
            },
            {
                heading:'FAO',
                content:'Food and Agriculture Organization of the United Nations. '
            },
            {
                heading:"Farmers' association",
                content:'Associations formed exclusively or partially by farmers who share a common interest or goal, organized in varying degrees of formality (FAO, 2024b, URI 37955 and IRU 2806).'
            },
            {
                heading:'International organization(non-FAO)',
                content:' An entity established by a treaty or other instrument governed by international law and possessing its own international legal personality (United Nations Department for General Assembly and Conference Management, 2024).'
            },
            {
                heading:'Large enterprise',
                content:'Companies with more than 250 employees (FAO, 2024c, Entry 73706).'
            },
            {
                heading:'Local government entity',
                content:'Administration at the level below national government, e.g., county and borough councils (FAO, 2024b, URI 4412).'
            },
            {
                heading:'Medium-large size farm',
                content:'Family or commercial farm with a high degree of activity specialization. These farms are more likely to use modern techniques, technologies, and inputs and to market their output (McConnell and Dillon, 1997).'
            },
            {
                heading:'Academia or research institution',
                content:'Governmental or private establishment that is concerned with the pursuit of research, education and scholarship (European Union, 2024, ID 3599438; Law Insider, 2024).'
            },
            {
                heading:'Small-medium enterprise',
                content:'Companies with fewer than 50 employees are considered "small", and those with fewer than 250 are considered "medium" (FAO, 2024c, Entry 73706).'
            },
            {
                heading:'Smallholder farm',
                content:'Smallholder farms are often characterized by limited access to production factors, natural resources and finance; using outdated technology, low input quality and skills; low productivity and competitiveness resulting from inefficient farm structures, etc.(Note that FAO does not have an official definition of smallholders. Thus, the category instead describes smallholders’ distinguishing features and characteristics (FAO, 2020b; FAO, 2024c, Entry 2989)). '
            }
        ]
    },
    {
        heading:'SUSTAINABLE DEVELOPMENT GOALS',
        subHeading:'The "Sustainable Development Goals" category reflects the alignment of the initiative with the United Nations Sustainable Development Goals (SDGs) (United Nations Department of Department of Economic and Social Affairs, 2024).',
        data:[
            {
                heading:'GOAL 1: No poverty',
                content:'End poverty in all its forms everywhere. '
            },
            {
                heading:'GOAL 2: Zero Hunger ',
                content:'End hunger, achieve food security and improved nutrition and promote sustainable agriculture. '
            },
            {
                heading:'GOAL 3: Good Health and Well-being ',
                content:'Ensure healthy lives and promote well-being for all at all ages.  '
            },
            {
                heading:'GOAL 4: Quality Education',
                content:'Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.'
            },
            {
                heading:'GOAL 5: Gender Equality',
                content:'Achieve gender equality and empower all women and girls.'
            },
            {
                heading:'GOAL 6: Clean Water and Sanitation',
                content:'Ensure availability and sustainable management of water and sanitation for all.'
            },
            {
                heading:'GOAL 7: Affordable and Clean Energy ',
                content:'Ensure access to affordable, reliable, sustainable and modern energy for all.'
            },
            {
                heading:'GOAL 8: Decent Work and Economic Growth',
                content:'Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.'
            },
            {
                heading:'GOAL 9: Industry, Innovation and Infrastructure',
                content:'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation.'
            },
            {
                heading:'GOAL 10: Reduced Inequality',
                content:'Reduce inequality within and among countries.'
            },
            {
                heading:'GOAL 11: Sustainable Cities and Communities',
                content:'Make cities and human settlements inclusive, safe, resilient and sustainable.'
            },
            {
                heading:'GOAL 12: Responsible Consumption and Production',
                content:'Ensure sustainable consumption and production patterns.'
            },
            {
                heading:'GOAL 13: Climate Action',
                content:'Take urgent action to combat climate change and its impacts.'
            },
            {
                heading:'GOAL 14: Life Below Water',
                content:'Conserve and sustainably use the oceans, seas and marine resources for sustainable development.'
            },
            {
                heading:'GOAL 15: Life on Land ',
                content:'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss.'
            },
            {
                heading:'GOAL 16: Peace and Justice Strong Institutions',
                content:'Promote peaceful and inclusive societies for sustainable development, provided access to justice for all and build effective, accountable and inclusive institutions at all levels.'
            },
            {
                heading:'GOAL 17: Partnerships to achieve the Goals',
                content:'Strengthen the means of implementation and revitalize the Global Partnership for Sustainable Development.'
            }
        ]
    },
    {
        heading:'FOUR BETTERS',
        subHeading:'The "Four Betters" category reflects the alignment of the initiative with the Four Betters as identified in the FAO’s Strategic Framework 2022-31 (FAO, 2024i). ',
        data:[
            {
                heading:'Better production',
                content:'Ensure sustainable consumption and production patterns, through inclusive food and agriculture supply chains at local, regional and global level, ensuring resilient and sustainable and agrifood systems in a changing climate and environment. '
            },
            {
                heading:'Better nutrition',
                content:'End hunger, achieve food security and improved nutrition in all its forms (including promoting nutritious food and increasing access to healthy diets).  '
            },
            {
                heading:'Better environment',
                content:'Protect, restore and promote sustainable use of terrestrial and marine ecosystems and combat climate change (reduce, reuse, recycle, residual management) through more efficient, inclusive, resilient and sustainable agrifood systems.  '
            },
            {
                heading:'Better life',
                content:'Promote inclusive economic growth by reducing inequalities (urban/rural areas, rich/poor countries, men/women).  '
            }
        ]
    }
]

export const ReferencesData: ReferenceDataInterface[] = [
    {
        title: 'European Union.',
        description: '2024. Interactive Terminology for Europe (IATE). In: ',
        citation: 'IATE European Union terminology.',
        subDescription: ' [Cited 4 April 2024]. ',
        url: 'https://iate.europa.eu/home'
    },
    {
        title: 'FAO, Apimondia, CAAS & IZSLT.',
        description: '2021.',
        citation: 'Good beekeeping practices for sustainable apiculture.',
        subDescription: 'FAO Animal Production and Health Guidelines No. 25. Rome, Italy, FAO, IZSLT, Apimondia and CAAS. ',
        url: 'https://doi.org/10.4060/cb5353en'
    },

    {
        title: 'FAO & WHO.',
        description: ' 2011. General Principles of Food Hygiene.',
        citation: '',
        subDescription: ' [Cited 27 April 2024].',
        url: 'https://www.fao.org/fao-who-codexalimentarius/codex-texts/codes-of-practice/en/'
    },
    {
        title: 'FAO.',
        description: ' 2010. Glossary. In: ',
        citation: 'AQUASTAT FAO’s Global Information System on Water and Agriculture.',
        subDescription: ' [Cited 4 April 2024]. ',
        url: 'https://www.fao.org/aquastat/en/databases/glossary/'
    },
    {
        title: 'FAO.',
        description: ' 2013. Good practices at FAO: Experience capitalization for continuous learning. FAO. [Cited 4 April 2024].',
        citation: '',
        subDescription: '',
        url: ' https://www.fao.org/documents/card/en/c/dc5e5669-b422-5fde-aabd-2ab6299a31d9'
    },
    {
        title: 'FAO.',
        description: ' 2014. ',
        citation: 'Food Losses and Waste in the Context of Sustainable Food Systems. A Report by the High Level Panel of Experts on Food Security and Nutrition.',
        subDescription: ' HLPE Report 8.',
        url: 'https://www.fao.org/policy-support/tools-and-publications/resources-details/en/c/854257/'
    },
    {
        title: 'FAO.',
        description: ' 2017a. EU Action for SMART VILLAGES. In: ',
        citation: 'Family Farming Knowledge Platform.',
        subDescription: ' [Cited 4 April 2024]. ',
        url: 'https://www.fao.org/family-farming/detail/en/c/883236/'
    },
    {
        title: 'FAO. ',
        description: '2017b. What role can Agricultural Extension and Advisory Services play in realizing gender equality and improved nutrition? Food Security and Nutrition Forum No. 141.',
        citation: '',
        subDescription: ' [Cited 4 April 2024]. ',
        url: 'https://www.fao.org/policy-support/tools-and-publications/resources-details/en/c/1155238/'
    },
    {
        title: 'FAO.',
        description: ' 2019.',
        citation: ' The international Code of Conduct for the sustainable use and management of fertilizers. ',
        subDescription: 'Rome, Italy, FAO. ',
        url: 'https://doi.org/10.4060/CA5253EN'
    },
    {
        title: 'FAO.',
        description: ' 2020a.',
        citation: ' Ecosystem approach to aquaculture management: Handbook.',
        subDescription: 'Rome, Italy, FAO.',
        url: 'https://doi.org/10.4060/ca7972en'
    },
    {
        title: 'FAO.',
        description: ' 2020b. ',
        citation: 'Empowering smallholders and family farms in Europe and Central Asia. Regional Synthesis Report.',
        subDescription: ' Budapest, Hungary, FAO. ',
        url: 'https://www.fao.org/documents/card/en/c/ca9586en'
    },
    {
        title: 'FAO.',
        description: ' 2024a. AGROVOC Multilingual Thesaurus. In: ',
        citation: 'AGROVOC Multilingual Thesaurus. ',
        subDescription: '[Cited 4 April 2024]. ',
        url: 'https://agrovoc.fao.org/browse/agrovoc/en/'
    },
    {
        title: 'FAO. ',
        description: '2024b. Digital Villages Initiative in Europe and Central Asia. In:',
        citation: ' Digital Villages Initiative in Europe and Central Asia.',
        subDescription: ' [Cited 4 April 2024].',
        url: 'https://www.fao.org/digital-villages-initiative/europe/en'
    },
    {
        title: 'FAO. ',
        description: '2024c. FAO Terminology Portal. In: ',
        citation: 'FAO Terminology Portal.',
        subDescription: ' [Cited 4 April 2024]. ',
        url: 'https://www.fao.org/faoterm/en'
    },
    {
        title: 'FAO.',
        description: ' 2024d. NOCS. In: ',
        citation: 'Multilingual database of Names of Countries and Territories (NOCS).',
        subDescription: '[Cited 4 April 2024]. ',
        url: 'https://www.fao.org/nocs/en'
    },
    {
        title: 'FAO.',
        description: ' 2024e. Themes. In:',
        citation: ' MainTopics.',
        subDescription: ' [Cited 1 May 2024].',
        url: 'https://www.fao.org/themes/en'
    },
    {
        title: 'FAO.',
        description: ' 2024f. Fisheries and Resources Monitoring System (FIRMS) Concepts & Definitions. ',
        citation: '',
        subDescription: '[Cited 4 April 2024]. ',
        url: 'https://firms.fao.org/firms/concepts/en'
    },
    {
        title: 'FAO. ',
        description: '2024g. All definitions. In: ',
        citation: ' FAO SOILS PORTAL.',
        subDescription: ' [Cited 4 April 2024].',
        url: 'https://www.fao.org/soils-portal/about/all-definitions/en/'
    },
    {
        title: 'FAO. ',
        description: '2024h. SFVC vocabulary. In:',
        citation: ' Sustainable Food Value Chains Knowledge Platform.',
        subDescription: ' [Cited 4 April 2024]. ',
        url: 'https://www.fao.org/sustainable-food-value-chains/what-is-it/sfvc-vocabulary/en/'
    },
    {
        title: 'FAO. ',
        description: '2024i. FAO Strategic Framework 2022-31. In: ',
        citation: 'StrategicFramework.',
        subDescription: ' [Cited 1 May 2024]. ',
        url: 'https://www.fao.org/strategic-framework/en'
    },
    {
        title: 'Joiner, J. & Okeleke, K. ',
        description: '2019. ',
        citation: 'E-commerce in agriculture: new business models for smallholders’ inclusion into the formal economy. ',
        subDescription: 'GSM Association. ',
        url: 'https://www.gsma.com/mobilefordevelopment/resources/e-commerce-in-agriculture-new-business-models-for-smallholders-inclusion-into-the-formal-economy/'
    },
    {
        title: 'Jolly, C.M. & Clonts, H.A.',
        description: ' 2020. ',
        citation: 'Economics of Aquaculture. ',
        subDescription: 'Boca Raton, CRC Press. ',
        url: ' https://doi.org/10.1201/9781003075165'
    },
    {
        title: 'Kay, R.D. ',
        description: '1986.',
        citation: 'Farm Management: Planning, Control, and Implementation. ',
        subDescription: 'McGraw-Hill. ',
        url: 'https://books.google.com/books/about/Farm_Management.html?id=ZxcqyhiSgSoC'
    },
    {
        title: 'Law Insider. ',
        description: '2024. Law Insider Dictionary. In: ',
        citation: ' Law Insider. ',
        subDescription: '[Cited 4 April 2024]. ',
        url: 'https://www.lawinsider.com/dictionary/'
    },
    {
        title: 'Loukos, P. & Arathoon.',
        description: ' 2022. ',
        citation: 'Assessment of smart farming solutions for smallholders in low and middle-income countries.',
        subDescription: 'GSM Association, Digital Agri Hub.',
        url: 'ttps://www.gsma.com/mobilefordevelopment/resources/assessment-of-smart-farming-solutions-for-smallholders-in-low-and-middle-income-countries/'
    },
    {
        title: 'McConnell, D.J. & Dillon, J.L. ',
        description: '1997. ',
        citation: 'Farm Management for Asia: a Systems Approach. ',
        subDescription: '. FAO Farm Systems Management Series 13. Rome, Italy',
        url: 'https://www.fao.org/3/w7365e/w7365e00.htm#Contents'
    },
    {
        title: 'Miller, C., Saroja, V.N. & Linder, C.',
        description: ' 2013. ',
        citation: 'ICT uses for inclusive agricultural value chains.',
        subDescription: 'Rome, Italy, FAO. ',
        url: 'https://www.fao.org/sustainable-food-value-chains/library/details/en/c/267215/'
    },
    {
        title: 'O’Reilly, C. & Binns, A.J.M.',
        description: '2019. The Three Stages of Disruptive Innovation: Idea Generation, Incubation, and Scaling.',
        citation: 'California Management Review,',
        subDescription: ' 61(3): 49–71. ',
        url: 'https://doi.org/10.1177/0008125619841878'
    },
    {
        title: 'Oxford English Dictionary. ',
        description: 'undated. Application software Quick search results. In: ',
        citation: 'OED Oxford English Dictionary.',
        subDescription: ' [Cited 4 April 2024]. ',
        url: 'https://www.oed.com/search/dictionary/?scope=Entries&q=application+software&tl=true'
    },
    {
        title: 'PCMag.',
        description: '2024. Definition of desktop application. In: ',
        citation: 'PCMAG.',
        subDescription: ' [Cited 4 April 2024]. ',
        url: 'https://www.pcmag.com/encyclopedia/term/desktop-application'
    },
    {
        title: 'Raithatha, R.',
        description: ' 2020.',
        citation: 'Digitising payments in agricultural value chains: The revenue opportunity to 2025.',
        subDescription: ' GSM Association. ',
        url: 'https://www.gsma.com/mobilefordevelopment/resources/digitising-payments-in-agriculturalvalue-chains-the-revenue-opportunity-to-2025/'
    },
    {
        title: 'Sibona, F., Chiavarini, L., Bortoletto, A. & Mainiero, S.',
        description: '2020. Innovation in farming: an engaging and rewarding business model to foster digitalization.',
        citation: 'CERN IdeaSquare Journal of Experimental Innovation, ',
        subDescription: '4(1): 9–15. ',
        url: 'https://doi.org/10.23726/cij.2020.1052'
    },
    {
        title: 'Sylvester, G.',
        description: '2016. ',
        citation: 'E-agriculture strategy guide Piloted in Asia-Pacific countries. ',
        subDescription: 'Rome, Italy, FAO. ',
        url: 'https://www.fao.org/documents/card/en/c/24f624ea-7891-45e8-9b24-66cbf13f004d/'
    },
    {
        title: 'The Engine Room Library & International Committee of the Red Cross.',
        description: 'undated. Messaging Apps. In: ',
        citation: 'The Engine Room Library. ',
        subDescription: ' [Cited 4 April 2024]. ',
        url: 'https://library.theengineroom.org/'
    },
    {
        title: 'Treinen, S. & van der Elstraeten, A. ',
        description: '2018.',
        citation: 'Gender and ICTs Mainstreaming gender in the use of information and communication technologies (ICTs) for agriculture and rural development.',
        subDescription: 'Rome, Italy, FAO. ',
        url: 'https://www.fao.org/documents/card/fr?details=I8670EN/'
    },
    {
        title: 'Tsan, M., Totapally, S., Hailu, M. & Addom, B.K. ',
        description: '2019. ',
        citation: 'The Digitalisation of African Agriculture Report 2018-2019.',
        subDescription: '9. Centre for Agricultural and Rural Cooperation. ',
        url: 'https://hdl.handle.net/10568/101498'
    },
    {
        title: 'United Nations Department for General Assembly and Conference Management.',
        description: ' 2024. The United Nations Terminology Database. In:',
        citation: 'UNTERM. ',
        subDescription: '[Cited 4 April 2024].',
        url: 'https://unterm.un.org/unterm2/en/'
    },
    {
        title: 'United Nations Department of Department of Economic and Social Affairs.',
        description: '2024.',
        citation: '',
        subDescription: 'The 17 Goals. [Cited 1 May 2024]. ',
        url: 'https://sdgs.un.org/goals'
    },
    {
        title: 'Wongtschowski, M., Belt, J., Heemskerk, W. & Kahan, D.',
        description: '2013.',
        citation: 'The business of agricultural business services: Working with smallholders in Africa.',
        subDescription: '. Royal Tropical Institute, Amsterdam; Food and Agriculture Organization of the United Nations, Rome; and Agri-ProFocus, Arnhem. ',
        url: 'https://www.fao.org/sustainable-food-value-chains/library/details/en/c/267152/'
    }

]