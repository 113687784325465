import React from "react";
import { Pagination as Pag } from "react-bootstrap";

interface IProps {
  currentPage: number;
  pages: number;
  changePage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination = ({ currentPage, pages, changePage }: IProps) => {
  const renderPages = () => {
    const range = 2;

    let start = Math.max(1, currentPage - range);
    let end = Math.min(pages, currentPage + range);

    // Ensure we have a fixed number of page items displayed (2*range + 1)
    while (end - start < 2 * range && end < pages) {
      end++;
    }

    while (end - start < 2 * range && start > 1) {
      start--;
    }

    const pagesArray = Array.from(
      { length: end - start + 1 },
      (_, index) => start + index
    );

    const pageItems = pagesArray.map((page) => (
      <Pag.Item
        key={page}
        active={page === currentPage}
        onClick={() => changePage(page)}
      >
        {page}
      </Pag.Item>
    ));

    if (start > 2) {
      pageItems.unshift(<Pag.Ellipsis key="start-ellipsis" />);
      pageItems.unshift(
        <Pag.Item key={1} onClick={() => changePage(1)}>
          {1}
        </Pag.Item>
      );
    } else if (start === 2) {
      // If start is 2, add the first page
      pageItems.unshift(
        <Pag.Item key={1} onClick={() => changePage(1)}>
          {1}
        </Pag.Item>
      );
    }

    if (end < pages - 1) {
      pageItems.push(<Pag.Ellipsis key="end-ellipsis" />);
      pageItems.push(
        <Pag.Item key={pages} onClick={() => changePage(pages)}>
          {pages}
        </Pag.Item>
      );
    } else if (end === pages - 1) {
      // If end is pages - 1, add the last page
      pageItems.push(
        <Pag.Item key={pages} onClick={() => changePage(pages)}>
          {pages}
        </Pag.Item>
      );
    }

    return pageItems;
  };

  return (
    <div>
      <Pag>
        <Pag.First onClick={() => changePage(1)} disabled={currentPage === 1} />
        <Pag.Prev
          onClick={() => changePage((page) => page - 1)}
          disabled={currentPage === 1}
        />
        {pages > 7 ? (
          <>{renderPages()}</>
        ) : (
          Array.from(Array(pages)).map((_, index) => {
            const page = index + 1;
            return (
              <Pag.Item
                key={page}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pag.Item>
            );
          })
        )}
        <Pag.Next
          onClick={() => changePage((page) => page + 1)}
          disabled={currentPage === pages}
        />
        <Pag.Last
          onClick={() => changePage(pages)}
          disabled={currentPage === pages}
        />
      </Pag>
    </div>
  );
};

export default Pagination;
