import axios from "axios";
import { useEffect, useState } from "react";

interface IProps {
  id1: string;
  id2: string;
}

const useCorrelatedProjects = ({ id1, id2 }: IProps) => {
  const [correlatedProjects, setCorrelatedProjects] = useState<
    Partial<Project>[]
  >([]);

  useEffect(() => {
    if (id1 && id2) {
      axios
        .get("https://data.apps.fao.org/fao-agrid-get-similar-projects", {
          params: { id1, id2 },
        })
        .then((response) => {
          setCorrelatedProjects(response.data);
        })
        .catch((error) => {
          console.error("Error fetching similar projects:", error);
        });
    }
  }, [id1, id2]);

  return correlatedProjects;
};

export default useCorrelatedProjects;
