import { Accordion, Col } from "react-bootstrap";
import Page from "../templates/Page";
import { ReferencesData, TaxonomyTypeData } from "../constants/taxonomyConstants";
import References from "../components/References";
import AccordionItemData from "../components/AccordionItem";
import { COLORS } from "../styles/colors";

const TaxonomyPage = () => {
  return <Page title="Taxonomy and terminology">

    <div style={{ marginBottom: "1rem", backgroundColor: COLORS.PrimaryLight, padding: "1rem",fontSize:"1rem" }}>
      <p style={{ marginBottom: "1rem"}}>This page provides an overview of the taxonomy and corresponding terminology
        employed by FAO to research digital agriculture initiatives and structure them for
        seamless exploration of Observatory.</p>
      <p style={{ marginBottom: "1rem"}}>The <strong>taxonomy</strong> helps users navigate the wealth of information within the AgriTech Observatory. All initiatives are categorized according to several dimensions, including use case, technology used, thematic and geographic areas of application, target users, business model, and more.</p>
      <p style={{ marginBottom: "1rem"}}>In the ever-evolving field of digital agriculture, understanding the <strong>terminology</strong> is key. Definitions provided below shall guide users and researchers in understanding the categorization of digital agriculture initiatives.  </p>

      <p style={{ marginBottom: "1rem"}}>Multiple resources were consulted to create the taxonomy to align with the constantly evolving technology landscape. Most definitions were <strong>adapted</strong> to the context of digital agriculture and purposes of the AgriTech Observatory. </p>
    </div>
    <Col sm="12" style={{ marginBottom: "1rem" }}>
      <h2>Taxonomy breakdown and definitions</h2>
    </Col>

    <Accordion>
      {
        TaxonomyTypeData.map((rec, index) => {
          return (
            <AccordionItemData index={index.toString()} heading={rec.heading}>
              <Col sm="12" style={{ marginBottom: "1rem" }}>
                <h6>{rec.subHeading}</h6>
              </Col>
              {
                rec.data && rec.data.map(dataEntry => {
                  return (
                    <Col sm="12" style={{ marginBottom: "1rem" }}>
                      <strong>{dataEntry.heading}</strong>{' - ' }<span>{dataEntry.content}</span>
                    </Col>
                  )
                })
              }
            </AccordionItemData>
          )
        })
      }
    </Accordion>

    <Col sm="12" style={{ marginBottom: "1rem" }}>
      <h2>References</h2>
    </Col>
    {
      ReferencesData.map(rec => {
        return <References record={rec} />
      })
    }

  </Page>;
};

export default TaxonomyPage;
