export const COLORS = {
  Primary: "#116AAB",
  PrimaryLight: "#E5ECF4",
  White: "#fff",
  Black: "#000",
  GrayDark: "#545454",
  GrayMedium: "#999999",
  GrayLight: "#F2F2F2",
  WhiteFAO: "#F7F8F9",
  Orange: "#F58320",
  Caption: "#1C4767",
  Emergency: "#980000",
  UNBlue: "#5792c9",
  Title: "#545454",
  Border: "#ced4da",
  FooterTitle: "#999",
};
