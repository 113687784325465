import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as Tableau from "tableau-api-js";
import FilterSelectDropdown from "../components/FilterSelectDropdown";
import Icon from "../components/Icon";
import ProjectSection from "../components/ProjectSection";
import TableauCharts from "../components/TableauCharts";
import useAnalyticsFilters from "../hooks/useAnalyticsFilters";
import { COLORS } from "../styles/colors";
import Page from "../templates/Page";

const AnalyticsPage = () => {
  const tableauContainerRef = useRef<HTMLDivElement>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { t } = useTranslation();

  const viz = useRef<any>();
  const sheet = useRef<any>();
  const activeSheet = useRef<any>();

  const mapSheet = 2;

  const [activeDropdown, setActiveDropdown] = useState<string>(null);

  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);

  const {
    loading,
    initiatives,
    countryCount,
    initiativesCount,
    page,
    setPage,
    pages,
    filters,
    selectedFilters,
    setSelectedFilters,
    resetFilters,
    searchTerm,
    setSearchTerm,
    areFiltersEmpty,
  } = useAnalyticsFilters();

  useEffect(() => {
    const url =
      "https://tableau.apps.fao.org/views/AgriTO19-01-2024/AgriTODashboard?:origin=card_share_link&:embed=n";

    const options = {
      hideTabs: true,
      hideToolbar: true,
      width: "100%",
      height: 991,
      onFirstInteractive: () => {
        sheet.current = viz.current.getWorkbook().getActiveSheet();
        activeSheet.current = viz.current
          .getWorkbook()
          .getActiveSheet()
          .getWorksheets()[mapSheet];
        viz.current.addEventListener(
          window.tableau.TableauEventName.MARKS_SELECTION,
          onMarksSelection
        );
      },
    };

    viz.current = new Tableau.Viz(tableauContainerRef.current, url, options);

    return () =>
      viz.current.removeEventListener(
        window.tableau.TableauEventName.MARKS_SELECTION,
        onMarksSelection
      );
  }, []);

  function onMarksSelection(marksEvent) {
    return marksEvent.getMarksAsync().then(reportSelectedMarks);
  }

  function reportSelectedMarks(marks) {
    for (var markIndex = 0; markIndex < marks.length; markIndex++) {
      var pairs = marks[markIndex].getPairs();

      for (var pairIndex = 0; pairIndex < pairs.length; pairIndex++) {
        var pair = pairs[pairIndex];
        /* console.log("PARAMS", pair.fieldName, pair.formattedValue); */
        switch (pair.fieldName) {
          case "Country or territory":
            setFilterData({ country: [pair.formattedValue] });
            break;
          case "Usecase_filter":
            setFilterData({ use_case: [pair.formattedValue] });
            break;
          case "Theme_filter":
            setFilterData({ theme: [pair.formattedValue] });
            break;
          case "Technology_filter":
            setFilterData({ technology: [pair.formattedValue] });
            break;
          default:
            break;
        }
      }
    }
  }

  const setFilterData = (data) => {
    setSelectedFilters((state) => {
      const newData = { ...state, ...data };
      return newData;
    });
  };

  // On change Search (group)
  useEffect(() => {
    if (sheet.current) {
      sheet.current.applyFilterAsync(
        "Search",
        searchTerm,
        window.tableau.FilterUpdateType.REPLACE
      );
    }
  }, [searchTerm]);

  const filterList: { [key: string]: { enableSearch?: boolean } } = {
    type: {},
    country: { enableSearch: true },
    use_case: {},
    theme: { enableSearch: true },
    technology: { enableSearch: true }
  }

  const advancedFilterList: { [key: string]: { enableSearch?: boolean } } = {
    business_model: {},
    maturity: {},
    fvc: {},
    users: { enableSearch: true },
    organization_type: { enableSearch: true },
    origin_country: { enableSearch: true },
    sdg: { enableSearch: true },
    four_betters: {},
  };

  const applyFilter = useCallback(
    (key: string, filterName: string): void => {
      console.log(key);
      if (sheet.current) {
        if (selectedFilters[key].length === 0) {          
          sheet.current.applyFilterAsync(
            filterName,
            "",
            window.tableau.FilterUpdateType.ALL
          );
        } else {
          sheet.current.applyFilterAsync(
            filterName,
            [...selectedFilters[key]],
            window.tableau.FilterUpdateType.REPLACE
          );
        }
      }
    },
    [filters, selectedFilters]
  );

  useEffect(
    () => applyFilter("business_model", "Business model"),
    [selectedFilters.business_model]
  );
  useEffect(
    () => applyFilter("country", "Country or territory"),
    [selectedFilters.country]
  );
  useEffect(
    () => applyFilter("four_betters", "Four betters"),
    [selectedFilters.four_betters]
  );
  useEffect(
    () => applyFilter("maturity", "Maturity"),
    [selectedFilters.maturity]
  );
  useEffect(() => applyFilter("sdg", "SDGs"), [selectedFilters.sdg]);
  useEffect(
    () => applyFilter("technology", "Technology_filter"),
    [selectedFilters.technology]
  );
  useEffect(
    () => applyFilter("theme", "Theme_filter"),
    [selectedFilters.theme]
  );
  useEffect(() => applyFilter("type", "Type"), [selectedFilters.type]);
  useEffect(() => applyFilter("fvc", "Food value chain stage"), [selectedFilters.fvc ]);
  useEffect(
    () => applyFilter("use_case", "Usecase_filter"),
    [selectedFilters.use_case]
  );
  useEffect(() => applyFilter("users", "Users"), [selectedFilters.users]);
  useEffect(
    () => applyFilter("organization_type", "Organization type"),
    [selectedFilters.organization_type]
  );

  return (
    <Page
      title="AgriTech Observatory"
      subtitle="Navigate digital agriculture developments in Europe and Central Asia​"
      actions={
        <Button className="d-block d-sm-none">
          <Icon
            icon={isMenuOpen ? "close" : "filter"}
            onClick={() => setIsMenuOpen((state) => !state)}
          />
        </Button>
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <TableauDashboardContent>
          <TableauDashboardFilters $menuOpen={isMenuOpen}>
            <div
              style={{
                borderTop: `1px solid ${COLORS.Border}`,
                borderBottom: `1px solid ${COLORS.Border}`,
                padding: "0.5rem 0",
              }}
            >
              <h1 style={{ fontSize: "2rem", fontWeight: "500" }}>Filters</h1>
            </div>
            {Object.entries(filterList).map(([id, data]) => (
              <FilterSelectDropdown
                key={id}
                id={id}
                label={t(`home.filters.${id}.label`)}
                placeholder={t(`home.filters.${id}.placeholder`)}
                list={filters[id]}
                activeList={selectedFilters[id]}
                setState={setFilterData}
                disabled={loading}
                activeDropdown={activeDropdown}
                setActiveDropdown={setActiveDropdown}
                enableSearch={data.enableSearch}
              />
            ))}
            {advancedFiltersOpen &&
              Object.entries(advancedFilterList).map(([id, data]) => (
                <FilterSelectDropdown
                  key={id}
                  id={id}
                  label={t(`home.filters.${id}.label`)}
                  placeholder={t(`home.filters.${id}.placeholder`)}
                  list={filters[id]}
                  activeList={selectedFilters[id]}
                  setState={setFilterData}
                  disabled={loading}
                  activeDropdown={activeDropdown}
                  setActiveDropdown={setActiveDropdown}
                  enableSearch={data.enableSearch}
                />
              ))}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {!advancedFiltersOpen ? (
                <InteractiveButton onClick={() => setAdvancedFiltersOpen(true)}>
                  Show advanced filters
                </InteractiveButton>
              ) : (
                <InteractiveButton
                  onClick={() => setAdvancedFiltersOpen(false)}
                >
                  Hide advanced filters
                </InteractiveButton>
              )}
              {!areFiltersEmpty && (
                <InteractiveButton onClick={resetFilters}>
                  Reset filters
                </InteractiveButton>
              )}
            </div>
            <div
              style={{
                borderTop: `1px solid ${COLORS.Border}`,
                borderBottom: `1px solid ${COLORS.Border}`,
                padding: "0.5rem 0",
                marginTop: "2rem",
              }}
            >
              <h1 style={{ fontSize: "2rem", fontWeight: "500" }}>
                Initiatives
              </h1>
              <a href="#initiatives" style={{ cursor: "pointer" }}>
                List of initiatives
              </a>
            </div>
          </TableauDashboardFilters>
          <div style={{ flex: 1 }}>
            {!loading ? (
              <>
                <h1
                  style={{ fontSize: 32, fontWeight: 500, color: COLORS.Black }}
                >
                  <span style={{ fontWeight: 700, color: COLORS.Primary }}>
                    {initiativesCount}
                  </span>
                  {` initiatives in `}
                  <span style={{ fontWeight: 700, color: COLORS.Primary }}>
                    {countryCount}
                  </span>
                  {` ${countryCount === 1 ? "country" : "countries"} or ${
                    countryCount === 1 ? "territory" : "territories"
                  }`}
                </h1>
              </>
            ) : (
              <div>
                <h1 style={{ fontSize: 18, fontWeight: 500, color: "black" }}>
                  Loading results...
                </h1>
              </div>
            )}
            <TableauCharts
              tableauContainerRef={tableauContainerRef}
              initiatives={initiatives}
            />
          </div>
        </TableauDashboardContent>
        <ProjectSection
          page={page}
          projects={!loading ? initiatives : []}
          pages={pages}
          setPage={setPage}
          loading={loading}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>
    </Page>
  );
};

export default AnalyticsPage;

const TableauDashboardFilters = styled.div<{ $menuOpen: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 250px;

  @media (max-width: 574px) {
    ${(props) => !props.$menuOpen && "display: none;"}
  }
`;

const TableauDashboardContent = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;

  @media (max-width: 574px) {
    flex-direction: column;
  }
`;

const InteractiveButton = styled.p`
  color: rgb(17, 107, 172);
  font-size: 0.7rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
