import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
a {
  text-decoration: none;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}
`;

export default GlobalStyle;
