import { Link } from "react-router-dom";
import image1 from "../assets/submit/submit-1.png";
import image2 from "../assets/submit/submit-2.png";
import SectionTextWithImages from "../components/SectionTextWithImages";
import Page from "../templates/Page";

const SubmitPage = () => {
  return (
    <Page title="Submit your initiative">
      <SectionTextWithImages
        title="Showcase your agritech initiative"
        image={image1}
      >
        <p style={{ marginBottom: "1rem" }}>
          Do you have an agritech initiative applied in Europe and Central Asia
          that deserves recognition? We invite you to submit your initiative for
          a chance to be featured in our Observatory.
        </p>
        <p style={{ marginBottom: "1rem" }}>
          Simply complete the form linked below, and let your innovation take
          center stage.
        </p>
        <Link
          to="https://forms.office.com/Pages/ResponsePage.aspx?id=aMQ6Frir0ESB_dnbFeOvlvk04BRu2HxDtq5NCcYRlm9UQjNGV0lDSEJFWjlRU1VQS1I4TzQ4MzRSSC4u"
          target="_blank"
        >
          Submit your initiative
        </Link>
      </SectionTextWithImages>
      <SectionTextWithImages title="Request an edit" image={image2}>
        <p style={{ marginBottom: "1rem" }}>
          If you would like to propose edits or updates to an existing
          initiative, we welcome your input. Please reach out to us at:
          <br />
          <Link to="mailto:REU-Digital-Agriculture@fao.org">
            REU-Digital-Agriculture@fao.org
          </Link>
        </p>
        <p style={{ marginBottom: "1rem" }}>
          We look forward to highlighting the latest advancements in
          agricultural technology and sharing your success with our community.
        </p>
      </SectionTextWithImages>
    </Page>
  );
};

export default SubmitPage;
