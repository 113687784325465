import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Breadcrumb from "../components/Breadcrumb";
import Container from "../components/Container";
import Icon from "../components/Icon";
import ProjectCard from "../components/ProjectCard";
import RenderData from "../components/RenderData";
import TableauMap from "../components/TableauMap";
import useCorrelatedProjects from "../hooks/useCorrelatedProjects";
import Page from "../templates/Page";
import { COLORS } from "../styles/colors";

const AnalyticsDetailsPage = () => {
  const { id } = useParams();
  const [project, setProject] = useState<Project>();
  const correlatedProjects = useCorrelatedProjects({
    id1: project?.similar_ids?.id1,
    id2: project?.similar_ids?.id2,
  });

  const formatLink = (link: string)=>{
    console.log(link)
    if(/(http(s?)):\/\//i.test(link)) {
      return link
    }
    return `https://${link}`
  }

  const projectData = useMemo(() => {
    if (!project) return null;
    return [
      {
        field: "Sustainable development goals",
        data: [{ content: project.sdgs, type: "sdgs" }],
      },
      {
        field: "Type",
        data: [{ content: project.type, type: "badge" }],
      },
      {
        field: "Timeframe",
        data: [{ content: project.implemented_since }],
      },
      {
        field: "Use case",
        data: [{ content: project.use_case, type: "badge" }],
      },
      {
        field: "Link",
        data: [{ content: formatLink(project.website), type: "link" }],
      },
      {
        field: "Organization",
        data: [
          { content: project.organization },
          { content: project.organization_type, type: "badge" },
        ],
      },
      {
        field: "Partners",
        data: [{ content: project.partners }],
      },
      {
        field: "Description",
        data: [
          { content: project.description },
          { content: project.theme, type: "badge" },
        ],
      },
      {
        field: "Technology",
        data: [
          { content: project.technology_description },
          { content: project.technology, type: "badge" },
        ],
      },
      {
        field: "Target",
        data: [
          { content: project.user_description },
          { content: project.users, type: "badge" },
        ],
      },
      {
        field: "Business model",
        data: [
          { content: project.business_model_description },
          { content: project.business_model, type: "badge" },
        ],
      },
      {
        field: "Food value chain stage",
        data: [{ content: project.food_value_chain_stage, type: "badge" }],
      },
      {
        field: "Implementation stage",
        data: [{ content: project.maturity, type: "badge" }],
      },
      {
        field: "Impact",
        data: [
          { content: project.impact },
          { content: project.four_betters, type: "fourBetters" },
        ],
      },
      {
        field: "Source",
        data: [{ content: project.source }],
      },
      {
        field: "Last updated",
        data: [{ content: project.date }],
      },
    ];
  }, [project]);

  const loadProject = useCallback(async () => {
    try {
      setProject(undefined);
      const { data }: { data: Project } = await axios.get(
        "https://data.apps.fao.org/fao-agrid-get-project-by-id",
        {
          params: {
            id,
          },
        }
      );
      setProject(data);
    } catch (error) {
      console.log("error", error);
    }
  }, [id]);

  useEffect(() => {
    loadProject();
  }, [id, loadProject]);

  if (!project) {
    return (
      <Container>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "5rem 0",
          }}
        >
          <Spinner />
        </div>
      </Container>
    );
  }

  return (
    <Page title={project.name} subtitle={project.sub_name}>
      <Breadcrumb
        sections={[
          { id: "analytics", name: "Home", link: "/analytics" },
          { id: "id", name: project.name },
        ]}
      />

      <Row>
        <Col sm={8} xs="12">
          <TableGrid>
            {projectData.map((row: any) => {
              const allContentEmpty: boolean = row.data.every(
                (item: any) =>
                  item.content === null || item.content.length === 0
              );

              if (allContentEmpty) {
                return null;
              }

              return (
                <>
                  <div>
                    <p style={{ fontWeight: "bold" }}>{row.field}</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.2rem",
                        justifyContent: "center",
                      }}
                    >
                      {row.data.map((data) => (
                        <RenderData data={data.content} type={data.type} />
                      ))}
                    </div>
                  </div>
                </>
              );
            })}
          </TableGrid>
        </Col>
        <Col
          sm={4}
          xs="12"
          style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {project.country_or_territory && (
            <TableauMap country={project.country_or_territory} />
          )}
          {project.country_of_origin && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon icon="globe2" />
                <h3
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    marginTop: "0.15rem",
                  }}
                >
                  From:
                </h3>
              </div>
              <RenderData data={project.country_of_origin} type="badge" />
            </div>
          )}
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon icon="geoAlt" />
              <h3
                style={{ fontSize: 18, fontWeight: 700, marginTop: "0.15rem" }}
              >
                Applied in:
              </h3>
            </div>
            <RenderData data={project.country_or_territory} type="badge" />
          </div>
          {correlatedProjects && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h3
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    marginTop: "0.15rem",
                  }}
                >
                  See also:
                </h3>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  marginTop: "0.3rem",
                }}
              >
                {correlatedProjects.map((project) => (
                  <ProjectCard key={project.id} project={project} />
                ))}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default AnalyticsDetailsPage;

const TableGrid = styled.div`
  & > div {
    display: grid;
    grid-template-columns: 30% 70%;
    border-top: 1px solid ${COLORS.Border};
    padding: 0.75rem 0;
  }

  @media (max-width: 767px) {
    & > div {
      display: grid;
      grid-template-columns: 1fr;
      border-top: 1px solid ${COLORS.Border};
      gap: 0.2rem;
    }
  }

  & > div:first-child {
    & > p {
      display: none;
    }

    & > div {
      grid-column: span 2;
    }
  }
`;
