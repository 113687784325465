import { useEffect, useRef } from "react";
import * as Tableau from "tableau-api-js";

const TableauMap = ({ country }) => {
  const tableauContainerRef = useRef<HTMLDivElement>();
  let viz = useRef<any>();
  const url = "https://tableau.apps.fao.org/views/AgriTO19-01-2024/subpage_map";

  useEffect(() => {
    const options = {
      hideTabs: true,
      hideToolbar: true,
      "Country or territory": country,
      height: 300,
      width: "100%",
    };
    viz.current = new Tableau.Viz(tableauContainerRef.current, url, options);
  }, [country]);

  return <div ref={tableauContainerRef} />;
};

export default TableauMap;
