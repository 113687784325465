import axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";

const useAnalyticsFilters = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [initiatives, setInitiatives] = useState([]);
  const [countryCount, setCountryCount] = useState(0);
  const [initiativesCount, setInitiativesCount] = useState(0);
  const [pages, setPages] = useState(0);

  const initialFilters: Filters = {
    type: [],
    country: [],
    use_case: [],
    theme: [],
    technology: [],
    business_model: [],
    maturity: [],
    fvc: [],
    users: [],
    organization_type: [],
    origin_country: [],
    sdg: [],
    four_betters: [],
  };

  const dataMaintainFilterKeys = ['country','type']

  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [selectedFilters, setSelectedFilters] =
    useState<Filters>(initialFilters);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const loadApi = useCallback(
    async (abortController) => {
      setLoading(true);

      try {
        const reponse = await axios.post(
          "https://data.apps.fao.org/fao-agrid-cascade-filter",
          {
            numberItems: "12",
            page: page.toString(),
            search: searchTerm,
            type: selectedFilters.type,
            countries: selectedFilters.country,
            useCase: selectedFilters.use_case,
            technology: selectedFilters.technology,
            theme: selectedFilters.theme,
            users: selectedFilters.users,
            businessModel: selectedFilters.business_model,
            fvc: selectedFilters.fvc,
            maturity: selectedFilters.maturity,
            organizationType: selectedFilters.organization_type,
            fourBetters: selectedFilters.four_betters,
            sdg: selectedFilters.sdg,
            countryOfOrigin: selectedFilters.origin_country,
          },
          { signal: abortController.signal }
        );

        if (reponse.status === 200) {
          const {
            data: initiatives,
            filters,
            number_country_of_territory,
            number_initiatives,
            pages,
          } = reponse.data as AnalyticsResponse;

          const updatedFilters = keepCountryDataIntact(filters);
          setInitiatives(initiatives);
          setCountryCount(number_country_of_territory);
          setInitiativesCount(number_initiatives);
          setFilters(updatedFilters);
          setPages(pages);
          setLoading(false);
        }
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request aborted");
        } else {
          // Handle other errors
          console.error("Error fetching data:", error);
        }
      }
    },
    [
      selectedFilters.type,
      selectedFilters.business_model,
      selectedFilters.country,
      selectedFilters.four_betters,
      selectedFilters.fvc,
      selectedFilters.maturity,
      selectedFilters.organization_type,
      selectedFilters.sdg,
      selectedFilters.technology,
      selectedFilters.theme,
      selectedFilters.use_case,
      selectedFilters.users,
      selectedFilters.origin_country,
      searchTerm,
      page,
    ]
  );

  const areFiltersEmpty = useMemo(() => {
    let empty = true;
    Object.entries(selectedFilters).forEach(([_, data]) => {
      if (Array.isArray(data) && data.length > 0) {
        empty = false;
        return;
      }
    });
    return empty;
  }, [selectedFilters]);

  useEffect(() => {
    const abortController = new AbortController();
    setPage(1);
    loadApi(abortController);

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, [selectedFilters, searchTerm]);

  useEffect(() => {
    const abortController = new AbortController();
    loadApi(abortController);

    return () => {
      // Cancel the request when the component unmounts
      abortController.abort();
    };
  }, [page]);

  const resetFilters = () => {
    setSelectedFilters(initialFilters);
  };

  const keepCountryDataIntact = (newFilters)=>{
    if(areFiltersEmpty ){
      return newFilters
    }

    dataMaintainFilterKeys.forEach(rec=>{
      newFilters[rec] = filters[rec]
    })
    return newFilters;
  }


  return {
    loading,
    initiatives,
    countryCount,
    initiativesCount,
    page,
    setPage,
    pages,
    filters,
    selectedFilters,
    setSelectedFilters,
    resetFilters,
    searchTerm,
    setSearchTerm,
    areFiltersEmpty,
  };

};



export default useAnalyticsFilters;
