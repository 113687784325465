import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { COLORS } from "../styles/colors";
import Pagination from "./Pagination";
import ProjectCard, { ProjectCardSkeleton } from "./ProjectCard";
import TextInput from "./TextInput";

interface IProps {
  page: number;
  projects: any[];
  pages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const ProjectSection = ({
  page,
  projects,
  pages,
  setPage,
  loading,
  searchTerm,
  setSearchTerm,
}: IProps) => {
  return (
    <>
      <div
        style={{
          marginTop: "2rem",
          marginBottom: "1rem",
          borderBottom: `1px solid ${COLORS.Border}`,
          padding: "1rem 0",
        }}
        id="initiatives"
      >
        <h2>List of initiatives</h2>
      </div>
      <TextInput
        placeholder="Search"
        value={searchTerm}
        setState={setSearchTerm}
      />
      <div style={{ marginTop: "1rem" }}>
        <ProjectCardGrid>
          {!loading ? (
            <>
              {projects.map((project) => (
                <ProjectCard key={project.id} project={project} />
              ))}
            </>
          ) : (
            <>
              {new Array(12).fill("").map((_, i) => (
                <ProjectCardSkeleton key={i} />
              ))}
            </>
          )}
        </ProjectCardGrid>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            marginTop: "1rem",
          }}
        >
          {page !== 0 && (
            <Pagination currentPage={page} pages={pages} changePage={setPage} />
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectSection;

const ProjectCardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media (max-width: 574px) {
    grid-template-columns: 1fr;
  }
`;
