import React, { useState } from "react";
import {
  ArrowClockwise,
  ArrowRight,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Filter,
  GeoAlt,
  Globe2,
  List,
  X,
} from "react-bootstrap-icons";

const icons = {
  close: X,
  bars: List,
  xmark: X,
  rotate: ArrowClockwise,
  check: Check,
  arrowRight: ArrowRight,
  filter: Filter,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  globe2: Globe2,
  geoAlt: GeoAlt,
};

type Icon = keyof typeof icons;

interface IProps {
  icon: Icon;
  tooltip?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const Icon = ({ icon, tooltip, onClick, ...props }: IProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const RenderIcon = icons[icon] as React.ComponentType;

  return (
    <span
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 32,
        width: 32,
      }}
      onClick={onClick}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {/* <FontAwesomeIcon icon={icons[icon]} {...props} /> */}
      <RenderIcon />
      <div
        style={{
          position: "absolute",
          left: 0,
          bottom: "100%",
          textAlign: "center",
          display: tooltip && showTooltip ? "block" : "none",
          background: "black",
          color: "white",
          padding: "0.5rem",
          borderRadius: "4px",
          transform: "translateY(-5px)",
          whiteSpace: "nowrap",
          fontSize: "0.9rem",
        }}
      >
        {tooltip}
      </div>
    </span>
  );
};

export default Icon;
